$bg-color: #261736;


body {
  background-color: $bg-color;
  background: radial-gradient(ellipse at center, $bg-color 0%, #421872 200%);
}

.main-wrapper {
  position: relative;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
}

.noise-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
  }
}

.noise {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(https://www.dropbox.com/s/h7ab1c82ctzy83n/noise.png?raw=1) 0 0;
  background-size: 320px 320px;
	opacity: .35;
	animation: noise 1s steps(8,end) infinite both;
  z-index: 1;
}

.lottie_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}