@import 'animation.scss';

.bubbles {
  position: absolute;
  width: 90%;
  left: 5%;
  height: 100%;


  .bubble {
    position: absolute;

    &.small {
      width: 5.79px;
      height: 6px;
      background: url('../assets/bubbles/small.svg') no-repeat center center / cover;
    }

    &.medium {
      width: 13.5px;
      height: 14px;
      background: url('../assets/bubbles/medium.svg') no-repeat center center / cover;
    }

    &.big {
      width: 20.5px;
      height: 21.26px;
      background: url('../assets/bubbles/big.svg') no-repeat center center / cover;
    }


  }
}


.statistic {

  .bubble {

    bottom: 0;

    &-0 {
      left: 14px;
    }

    &-1 {
      right: 8.5px;
    }

    &-2 {
      left: 11px;
    }

    &-3 {
      left: 27px;
    }

    &-4 {
      right: 24.5px;
    }

    &-5 {
      left: 42px;
    }

    &-6 {
      left: 44px;
    }

    &-7 {
      left: 32px;
    }

    &-8 {
      right: 38.2px;
    }

    &-9 {
      right: 10.2px;
    }

    &-10 {
      right: 13px;
    }
  }

}

.footer {

  .bubble {
    bottom: 0;

    &-0 {
      left: 19px;
    }

    &-1 {
      left: 76px;
    }

    &-2 {
      left: 94px;
    }

    &-3 {
      left: 94px;
    }

    &-4 {
      left: 86px;
    }

    &-5 {
      left: 194px;
    }

    &-6 {
      left: 264px;
    }

    &-7 {
      left: 305px;
    }

    &-8 {
      left: 311px;
    }

    &-9 {
      left: 374px;
    }

    &-10 {
      right: 403px;
    }

    &-11 {
      right: 365px;
    }

    &-12 {
      right: 355px;
    }

    &-13 {
      right: 262px;
    }

    &-14 {
      right: 250px;
    }

    &-15 {
      right: 195px;
    }

    &-16 {
      right: 144px;
    }

    &-17 {
      right: 136px;
    }

    &-18 {
      right: 42px;
    }

    &-19 {
      left: 16px;
    }

    &-20 {
      left: 32px;
    }

    &-21 {
      left: 81px;
    }

    &-22 {
      left: 203px;
    }

    &-23 {
      left: 203px;
    }

    &-24 {
      left: 198px;
    }

    &-25 {
      right: 362px;
    }

    &-26 {
      right: 216px;
    }

    &-27 {
      right: 183px;
    }

    &-28 {
      right: 154px;
    }

    &-29 {
      right: 120px;
    }

    &-30 {
      right: 104px;
    }

    &-31 {
      left: 35px;
    }

    &-32 {
      left: 49px;
    }

    &-33 {
      left: 47px;
    }

    &-34 {
      left: 49px;
    }

    &-35 {
      left: 100px;
    }

    &-36 {
      left: 114px;
    }

    &-37 {
      left: 232px;
    }

    &-38 {
      right: 228px;
    }

    &-39 {
      right: 241px;
    }

    &-40 {
      right: 188px;
    }

    &-41 {
      right: 192px;
    }

    &-42 {
      right: 178px;
    }

    &-43 {
      right: 134px;
    }

    &-44 {
      right: 101px;
    }


  }

}


.plan__pipe-inner .inner {

  .bubble {
    bottom: 0;

    &-0 {
      left: 3.6px;
    }

    &-1 {
      left: 5px;
    }

    &-2 {
      right: 5px;
    }

    &-3 {
      right: 1px;
    }

    &-4 {
      left: 2px;
    }

    &-5 {
      right: 3px;
    }

    &-6 {
      right: 5px;
    }

    &-7 {
      right: 3px;
    }

    &-8 {
      left: 3px;
    }

    &-9 {
      left: 4px;
    }

    &-10 {
      right: 11px;
    }

    &-11 {
      left: 5px;
    }

    &-12 {
      left: 1px;
    }

    &-13 {
      left: 6px;
    }

    &-14 {
      right: 1px;
    }

    &-15 {
      right: 1.5px;
    }

    &-16 {
      left: 1px;
    }

    &-17 {
      left: 2px;
    }

    &-18 {
      left: 6px;
    }

    &-19 {
      left: 2.4px;
    }

    &-20 {
      right: 5px;
    }

    &-21 {
      right: 4px;
    }

    &-22 {
      right: 3px;
    }

    &-23 {
      right: 2px;
    }

    &-24 {
      right: 1px;
    }

  }
}


.discord,
.plan__step.step-1 .plan-bg__pipe {

  .bubble {
    bottom: 0;

    &.small {
      width: 3.7px;
      height: 4px;
    }

    &.medium {
      width: 10px;
      height: 9px;
    }

    &.big {
      width: 15px;
      height: 14px;
    }

    &-0 {
      left: 5px;
    }

    &-1 {
      right: 6px;
    }

    &-2 {
      left: 3px;
    }

    &-3 {
      left: 6px;
    }

    &-4 {
      right: 5px;
    }

    &-5 {
      right: 5px;
    }

    &-6 {
      right: 3px;
    }

    &-7 {
      left: 6px;
    }

    &-8 {
      left: 8px;
    }

    &-9 {
      left: 8px;
    }

    &-10 {
      left: 13px;
    }

    &-11 {
      right: 6.7px;
    }

    &-12 {
      right: 13px;
    }

    &-13 {
      right: 5.29px;
    }

    &-14 {
      left: 12px;
    }

  }

}


.plan__step-progress .inner {

  .bubble {
    bottom: 0;

    &.small {
      width: 3.2px;
      height: 3px;
    }

    &.medium {
      width: 7.15px;
      height: 7px;
    }

    &.big {
      width: 12.7px;
      height: 12px;
    }

    &-0 {
      right: 5px;
    }

    &-1 {
      right: 7px;
    }

    &-2 {
      left: 50%;
    }

    &-3 {
      left: 11px;
    }

    &-4 {
      right: 8px;
    }

    &-5 {
      right: 14px;
    }

    &-6 {
      left: 7px;
    }

    &-7 {
      left: 14px;
    }

    &-8 {
      right: 10px;
    }
  }
}

.plan__step.final {

  .bubble {
    bottom: 0;

    &-0 {
      left: 21px;
    }

    &-1 {
      left: 31px;
    }

    &-2 {
      right: 142.5px;
    }

    &-3 {
      right: 42.5px;
    }

    &-4 {
      right: 42.5px;
    }

    &-5 {
      left: 78px;
    }

    &-6 {
      right: 48.5px;
    }

    &-7 {
      left: 18px;
    }

    &-8 {
      left: 34px;
    }

    &-9 {
      right: 64.5px;
    }

    &-10 {
      right: 40.5px;
    }

    &-11 {
      left: 37px;
    }

    &-12 {
      left: 49px;
    }

    &-13 {
      left: 51px;
    }

    &-14 {
      right: 49.2px;
    }

    &-15 {
      left: 19px;
    }
  }

}