@import 'animation.scss';
@import 'noise.scss';
@import 'components.scss';
@import 'service.scss';

$bg-color: #261736;
$lamex-color: #64F7F7;
$not-active-color: #F76464;
$active-color: #8DFF1F;
$dark-color: #101820;
$pink-color: #F764C6;
$white-color: #F2F4F8;
$input-color: #452B6E;

body {
  // width: 100%;
  // height: 100vh;
  background-color: $bg-color;
}

@keyframes zoomPortal
{  
0%   {transform: scale(1);}  
100% {transform: scale(10);}  
}

.dashboard-container {
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 3;
  width: 1360px;
  height: 920px;
  overflow: hidden;
  background: url("../../assets/season-2/backgrounds/dashboard-tube.png") no-repeat;
  background-position-x: 600px;
  background-position-y: -45%;
  padding: 80px 0px;
  margin-left: auto;
  display: flex;

  &.portal-container {
    margin: 0 auto;
    max-width: 100%;
    background: none;
  }

  .content-menu {
    margin: -15px 0px 5px;
    padding: 13px 20px;
    text-align: right;

    a {
      text-decoration: none;
      font-size: 20px;
      line-height: 20px;
      color: $lamex-color;
      text-shadow: 0px 1px 9px $lamex-color;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    span.menu-item {
      color: $pink-color;
      text-shadow: 0px 1px 9px $pink-color;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }
  @media (min-width: 1950px) {
    margin-left: inherit;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    background-position-x: 300px;
  }
  @media (max-width: 684px) {
    flex-direction: column;
    height: auto;
    background: none;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    padding: 80px 0px 0px;
    align-items: center;

    .content-menu {
      margin-top: 10px;
      text-align: center;
    }
  }
}

.dashboard-sidebar {
  width: 25%;
  @media (max-width: 1024px) {
    width: 33%;
  }
  @media (max-width: 684px) {
    width: auto;
    max-width: 450px;
    padding-bottom: 50px;
  }
}

.dashboard-content {
  width: 75%;
  @media (max-width: 1024px) {
    width: 67%;
  }
  @media (max-width: 684px) {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.experiences-container {
  display: flex;
  gap: 40px 20px;
  justify-content: space-between;
  margin-top: 50px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }  
}


.video {
  width: 70%;
  position: relative;
  border-radius: 20px!important;

  .logo {
    position: absolute;
    width: 150px;
    top: -10px;
    left: -30px;
    transform: rotate(-15deg);
  }

  @media (max-width: 684px) {
    width: 90%;

    .logo {
      width: 80px;
      top: -10px;
      left: -5px;
    }
  }

  video {
    width: 100%;
    border-radius: 20px;
  }
}

.lamex__balance-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .lamex-balance {
    color: $lamex-color;
    font-size: 26px;
    span {
      font-size: 14px;
    }
  }
  .lamex-status {
    text-align: right;
    text-decoration: underline;
    font-size: 12px;
    color: $not-active-color;

    &.active {
      color: $active-color;
    }
  }
}

.lamex-table {
  min-height: 500px;
  overflow-y: auto;
  table {
    color: $lamex-color;
    width: 100%;
    overflow-y: auto;
    thead {
      border-bottom: 1px dashed $lamex-color;
      th {
        text-align: inherit;
        padding: 10px 2px;
      }
    }
    tbody {
      td {
        padding: 5px 5px;
      }
    }
  }
}

.topUp-container {
  width: 70%;
  margin: 0 auto;
  @media (max-width: 684px) {
    width: 100%;
    .lamex-amount_input_container {
      width: 100%;
    }
  }
}

.yield-container {
  color: $lamex-color;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.future-card-container {
  background: $bg-color;
  border-radius: 8px;
  text-align: center;
  padding: 20px 20px 40px;
  color: $lamex-color;

  img {
    width: 80%;
    margin: 0px auto 20px;
  }
}

.progress__bar {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: $bg-color;
  border: 1.75px solid $dark-color;
  border-radius: 8px;
  padding: 0;
  box-shadow: 0px 1px 9px rgba(243, 92, 192, 0.72);

  .progress {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    padding: 0px 15px;

    &:after {
      border-radius: 8px;
      border: none;
      background-image: url("../../assets/season-2/inter-dimentionals/progressSections.png");
    }

    span, a {
      z-index: 10;
      color: rgba(255, 255, 255, 0.5);
    }

    &__received {
      text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    }

    &__inner {
      padding: 0 10px;
      font-size: 22px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      background-color: $pink-color;
      transform: translate(0, -50%);
      border-radius: 8px;
      overflow: hidden;

      span {
        z-index: 100;
      }
    }
  }
}

.progress_bar-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px;
  background: $text-bg-color;
  border-radius: 8px;

  .header__main-progress {
    width: 75%;
  }
  .progress__bar-button {
    width: 25%;
  }
  @media (max-width: 684px) {
    flex-wrap: wrap;
    .header__main-progress, .progress__bar-button {
      width: 100%;
    }
  }
}

.id__topup {
  padding: 0px;
  background: $text-bg-color;
  border-radius: 8px;
  height: 0px;
  overflow: hidden;
  transition: 0.2s;

  &.active {
    padding: 15px;
    height: auto;
    transition: 0.2s;
  }
  .header__main-progress {
    width: 75%;
  }
  .progress__bar-button {
    width: 25%;
  }
  @media (max-width: 684px) {
    flex-wrap: wrap;
    .header__main-progress, .progress__bar-button {
      width: 100%;
    }
  }
}


@keyframes shard1Movement {
  0%   {
    transform: translate(-300px, -200px);
    background: none;
    box-shadow: none;
  }
  100% {
    transform: translate(0px, 0px);
  }
}


@keyframes shard2Movement {
  0%   {
    transform: translate(300px, -200px);
    background: none;
    box-shadow: none;
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes shard3Movement {
  0%   {
    transform: translate(0px, 500px);
    background: none;
    box-shadow: none;
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.portal-container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  .portal-overlay {
    position: relative;
    .portal{
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-top: -50px;

      .blur {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(247, 100, 198, 0.3);
        filter: blur(200px);
      }
  
      img {
        width: 1000px;
      }
    }
  
    .shard {
      position: absolute;
      width: 7%;
      top: 0;
      left: 0;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 2px 2px 20px 25px rgba(255, 255, 255, 0.2);

      &.shard1 {
        top: 43%;
        left: 66%;
        animation: shard1Movement ease-in 3s;
      }

      &.shard2 {
        top: 43%;
        left: 27%;
        animation: shard2Movement ease-in 3s;
      }

      &.shard3 {
        top: 24.5%;
        left: 46.5%;
        animation: shard3Movement ease-in 3s;
      }
    }
    .portal-button {
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto;     
      margin-top: -20px;
      position: absolute;
      z-index: 1;
    }
  }
}


.id-counter {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -12px;
  right: -12px;
  background: $pink-color;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 4px solid rgba(255, 255, 255, 0.4);
}

.title-select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selector-content {
  background: #261736;
  padding: 0px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 0px;
  overflow: hidden;
  transition: height, 0.1s linear;

  &.active {
    padding: 10px;
    max-height: 1600px;
    transition: height, 0.3s linear;
  }

  .content-image {
    width: 23%;
    cursor: pointer;
    border-radius: 8px;

    &.active {
      border: 3px solid $id-color;
    }
  }

  @media (max-width: 1034px) {
    .content-image {
      width: 29%;
    }
  }

  @media (max-width: 684px) {
    .content-image {
      width: 47%;
    }
  }
}

.trait-modal {
  .section-container__wrapper {
    gap: 0px;
  }

  @media (max-width: 684px) {
    max-height: 90%;

    .section-container__wrapper {
      overflow-y: auto;
    }
  }

}

.pack-open-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.packs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 1100px) {
    justify-content: space-around;
  }

  .pack-container {
    width: 23%;
    @media (max-width: 1100px) {
      width: 47%;
    }
    @media (max-width: 440px) {
      width: 100%;
    }
  }
  .pack-container:nth-child(1) {
    width: 48%;
    @media (max-width: 1100px) {
      width: 60%;
    }
    @media (max-width: 440px) {
      width: 100%;
    }
  }

  .inner-packs {
    display: flex;
    flex-direction: row;
    width: 48%;
    flex-wrap: wrap;
    gap: 20px;

    .pack-container {
      width: 47%;
    }
    @media (max-width: 1100px) {
      width: 100%;
      justify-content: space-around;
    }
    @media (max-width: 440px) {
      .pack-container {
        width: 100%;
      }
    }
  }
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  // flex-wrap: wrap;

  .alpha-input {
    width: 320px;
    margin-right: 7px;
    background: $lamex-color;
    border: 1px solid $dark-color;
    height: 25px;
    color: $dark-color;
    padding: 0px 10px;
    border-radius: 4px;
  }
}

.marketplace-enter {
  color: $id-color;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 24px;
}

.absolute-preview {
  width: 150px;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  justify-content: space-around;
}

.evolve-card {
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;

  .evolve-col {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    .evolve-col {
      min-height: 600px;
    }
  }
}

.evolution-sidebar {
  width: 35%;
  position: relative;

  .back-button {
    position: absolute;
    top: 20px;
    left: 0px;
  }

  .preview-container {
    width: 100%;
    z-index: 2;
    margin-top: 80px;
  }

  @media (max-width: 684px) {
    width: 90%;
  }
}

.evolve-input {
  width: 100%;
  padding: 10px 5px;
  font-family: TerminusBold, sans-serif;
  background: $input-color;
  font-size: 20px;
  border: none;
  text-align: center;
  color: $white-color;
}