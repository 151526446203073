@import "fonts.scss";
@import "variables.scss";
@import "animation.scss";
@import "mixins.scss";
@import "bubbles.scss";

// Default styles

body {
  line-height: 1;
  font-family: Terminus, sans-serif;
  color: $main-color-text;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation-fill-mode: forwards;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  overflow: hidden;
}

div {
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

strong {
  font-weight: bold;
}

input {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#root {
  height: auto;
  max-width: 100%;
}

// Intro

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1c2f2b;
  position: fixed;
  z-index: 999999;

  .loader__container {
    max-width: 30%;
  }
}

.intro {
  // height: 1044px;
  background: radial-gradient(
    46.13% 46.13% at 50% 69.53%,
    #6b75d0 0%,
    #232256 100%
  );
}

.container_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  cursor: pointer;

  .button-title {
    position: absolute;
    font-family: Terminus, sans-serif;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: #101820;
  }
}
.header-scroll {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  //background-color: #0D063E;
  background: url("../assets/intro/pipe.svg") no-repeat center center / cover;
  //border: 1.75px solid #000;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &.active {
    display: none;
  }

  &__logo {
    margin-left: 40px;
    margin-top: -15px;
    width: 86px;
    height: 86px;
  }

  &__navs {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
  }

  &_nav {
    white-space: nowrap;
    margin-right: 8px;
    cursor: pointer;
    text-align: center;
    height: 38px;
    width: auto;
    padding: 0 35px;
    border-radius: 42px;
    background-color: #0f2320;
    border: 1.75px solid #101820;
    box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);
    color: #8dff1f;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
    font-size: 16px;
    font-family: TerminusBold, sans-serif;
    display: flex;
    align-items: center;

    &.router {
      background-color: rgb(100, 247, 247);
      border: 1.75px solid rgb(16, 24, 32);
      color: rgb(38, 23, 54);
    }

    span {
      color: #8dff1f;
      text-transform: uppercase;
      text-decoration: none;
      margin: auto;
      text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &_nav.disabled {
    &:hover {
      text-decoration: none;
      cursor: default;
    }

    span {
      color: #000;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    width: 247px;
    height: 56px;
    // background: url('../assets/intro/button-purple.svg') no-repeat center center / cover;
    display: flex;
    // margin: -7px 7px 0 0;
    cursor: pointer;

    span {
      margin: auto;
      text-transform: uppercase;
      font-size: 16px;
      color: #101820;
      font-weight: 700;
    }
  }
}

.bank_input_container {
  width: 45%;

  &.mint {
    width: 60%;
    margin: 20px 0;
  }

  &.home {
    width: 100%;
  }

  .input_title {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #8dff1f;
    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    font-family: TerminusBold, sans-serif;
    margin-bottom: 5px;

    &.small {
      font-family: Terminus, sans-serif;
      font-size: 14px;
      line-height: 16px;
      margin-top: 5px;
    }
  }

  .bank_input {
    height: 30px;
    width: 100%;
    background: #8dff1f;
    box-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    font-family: Terminus, sans-serif;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #0b302b;
    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

table {
  color: #8dff1f;
  width: 100%;
  overflow-y: auto;
  thead {
    border-bottom: 1px dashed #8dff1f;
    th {
      padding: 10px 2px;
    }
  }
  tbody {
    td {
      padding: 5px 5px;
    }
  }
}

.button_v2 {
  width: 100%;
  background: #718280;
  border: 1.75px solid #101820;
  box-sizing: border-box;
  border-radius: 8px;
  height: 50px;
  font-family: Terminus, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #8dff1f;
  text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.disabled {
    color: #51605e;
    cursor: unset;
    text-shadow: none;
  }
}

.popup.project__info {
  .icon {
    width: 145px;
    height: 145px;
    margin: 40px 0 20px;
  }

  .popup__panel-wrapper {
    color: #8dff1f;
    padding: 2rem 3rem;

    * {
      margin-bottom: 1rem;
    }

    a {
      color: #8dff1f;
      text-decoration: underline;
    }

    li {
      font-size: 1.5rem;
    }
    overflow-y: auto;

    // .account__settings {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-between;
    //   font-family: Terminus, sans-serif;
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 16px;
    //   line-height: 18px;
    //   letter-spacing: -0.04em;
    //   color: #8dff1f;
    //   text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);

    //   .account__username {
    //     background: none;
    //     border: none;
    //     font-family: Terminus, sans-serif;
    //     font-style: normal;
    //     font-weight: normal;
    //     font-size: 20px;
    //     line-height: 18px;
    //     letter-spacing: -0.04em;
    //     color: #8dff1f;
    //   }
    // }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 80px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #42592c;
    margin-top: 100px;
    margin-bottom: 100px;
    margin: 100 auto;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #8dff1f;
    opacity: 1;
  }

  .dash_line {
    width: 100%;
    height: 0px;
    border: 1px dashed #8dff1f;
    margin: 20px 0;
  }

  &.leaderboard {
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
      opacity: 0;
    }
  }

  .popup__panel {
    width: 800px;
    max-width: 800px;
    margin: 40px auto;

    &.bank_info {
      width: 600px;
      margin: auto;
    }

    &.private-bank {
      background: #101820;
    }
  }

  .bank_container {
    width: 80%;
    margin: 40px auto 20px;
  }

  .metamask_button {
    font-size: 14px;
    text-decoration: underline;
    margin-top: 15px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .stats_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 20px;
  }

  .bank_input_container {
    width: 45%;

    &.home {
      width: 100%;
    }

    .input_title {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: #8dff1f;
      text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
      font-family: TerminusBold, sans-serif;
      margin-bottom: 5px;

      &.small {
        font-family: Terminus, sans-serif;
        font-size: 14px;
        line-height: 16px;
        margin-top: 5px;
      }
    }

    .bank_input {
      height: 30px;
      width: 100%;
      background: #8dff1f;
      box-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
      font-family: Terminus, sans-serif;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: #0b302b;
      text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
      padding: 0px 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .tax_text {
    margin: 10px 0 30px 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #8dff1f;
    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    font-family: TerminusBold, sans-serif;
    margin-top: -10px;
  }

  .amount_input_container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .mutation-container {
      display: flex;
      align-items: left;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 15px;
      min-height: 100px;
      max-height: 250px;
      overflow-y: auto;

      p {
        padding-top: 30px;
      }
    }

    .input_title {
      font-family: Terminus, sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: #8dff1f;
      text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
      margin: 30px 0 20px;
    }

    .amount_input {
      position: relative;
      width: 80%;
      input {
        width: 100%;
        background: #0f2320;
        border: 1.75px solid #101820;
        color: #8dff1f;
        box-sizing: border-box;
        border-radius: 42px;
        font-size: 24px;
        line-height: 28px;
        padding: 5px 15px;
        margin-bottom: 40px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      .max_button {
        position: absolute;
        right: 10px;
        top: 5px;
        background: #718280;
        border: 1.75px solid #101820;
        box-sizing: border-box;
        border-radius: 42px;
        padding: 5px 25px;
        cursor: pointer;
      }
    }
    .staked-creep-card {
      width: 70px;

      .creep-image {
        width: 70px;
        height: 70px;
        margin-bottom: 7px;
        position: relative;
        border-radius: 20px;

        img {
          border-radius: 5px;
        }
      }
    }
  }

  .popup_text_container {
    max-width: 557px;
    margin: 0 auto;
    text-align: center;
  }

  .popup__title {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 59.7914px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.04em;
    text-transform: none;

    /* Colours/Bright Green */

    color: #8dff1f;

    text-shadow: 0px 2.42857px 21.8571px rgba(115, 255, 66, 0.72);
  }

  .popup__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    /* identical to box height */
    text-align: left;

    letter-spacing: -0.04em;

    /* Colours/Bright Green */

    color: #8dff1f;

    /* Terminal */

    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 32px 0px;
  }

  .popup__text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    /* or 112% */
    text-align: left;
    letter-spacing: -0.04em;
    color: #8dff1f;

    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
  }
  .center {
    text-align: center;
  }

  .table {
    width: 100%;
    margin: 30px 0;
  }

  .alien__text {
    font-family: AncientHand, sans-serif;
  }

  .staking_container {
    width: 80%;
    margin: 30px auto;
    text-align: left;

    .name-change__container {
      padding: 50px 0px 100px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;

      p {
        margin-bottom: 30px;
      }

      img.rename-creepz__img {
        width: 260px;
        border-radius: 5px;
      }

      .name-change__fullname {
        width: 100%;
        height: 48px;
        padding: 0px 15px;
        background: #0f2320;
        border: 1.75px solid #101820;
        box-sizing: border-box;
        border-radius: 42px;
        color: #8dff1f;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .input-container {
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #8dff1f;
        text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
        margin-bottom: 30px;

        p {
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          height: 48px;
          padding: 0px 15px;
          background: #0f2320;
          border: 1.75px solid #101820;
          box-sizing: border-box;
          border-radius: 42px;
          color: #8dff1f;
          font-size: 16px;

          &::placeholder {
            color: rgba(115, 255, 66, 0.72);
            text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.32);
          }
        }
      }
    }

    .selectControls {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .massSelect {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .bottom__buttons {
    width: 80%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 14px auto;
    padding: 10px 0px;
    background: #0c302b;
    border-top: 1px solid #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .button_v2 {
      width: 48%;
    }

    .name-change__price {
      width: 100%;
      height: 32px;
      text-align: center;
      margin: 15px auto;
      color: #8dff1f;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1.75px solid #101820;
      border-radius: 8px;
    }

    .name-change__summary {
      width: 75%;
      margin: 0 auto;
    }
  }

  .dash_line_staking {
    margin: 10px 0;
  }

  .staking-title {
    text-align: left;
    font-size: 24.62px;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: #8dff1f;
    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    font-family: Terminus, sans-serif;
    margin-top: 20px;
  }

  .popup__button-back {
    cursor: pointer;
    position: absolute;
    top: -10px;
    left: -20px;
    width: 64px;
    height: 64px;
    background-color: #eed815;
    border: 1.75px solid #101820;
    box-shadow: 0px 0px 71.1111px rgba(255, 64, 64, 0.9);
    border-radius: 16px;
    padding: 8px;
    z-index: 3;

    .wrapper {
      height: 100%;
      width: 100%;
      background-color: rgba(242, 244, 248, 0.31);
      border-radius: 8px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .staking_bottons-wrapper {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    left: 0px;
    bottom: 0px;
    right: 0px;
    margin: 0 auto;
    margin-bottom: -23px;
    justify-content: center;

    .popup__button {
      position: relative;
      left: 0%;
      transform: translateX(0%);
      width: 160px;
      bottom: 0;
    }

    .unstake__button {
      background: url("../assets/staking/unstake.svg") no-repeat center center /
        cover;
    }

    .stake__button {
      background: url("../assets/staking/stake.svg") no-repeat center center /
        cover;
    }

    .claim__button {
      background: url("../assets/staking/claim.svg") no-repeat center center /
        cover;
    }
  }

  .tabs-menu-wrapper,
  .creepz-layout-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
    position: relative;

    &.mutation {
      flex-wrap: wrap;
    }

    &.leaderboard {
      flex-wrap: nowrap;
    }

    .approve-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      // align-items: center;
      padding-top: 185px;
      text-decoration: none;
    }

    .staking-menu-item {
      cursor: pointer;
      width: 30%;
      height: 36px;
      background: rgba(0, 0, 0, 0.2);
      border: 1.75px solid #000000;
      box-sizing: border-box;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Terminus, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24.62px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.04em;
      color: #8dff1f;
      text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);

      &.leaderboard {
        width: 48%;
      }

      &.active {
        background: #8dff1f;
        border: 1.75px solid #8dff1f;
        box-sizing: border-box;
        border-radius: 9px;
        color: #0d352c;
      }
    }

    .creep-item {
      cursor: pointer;
      width: 30%;
      position: relative;

      img {
        border-radius: 20px;
        min-width: 30%;
      }

      .staking_icon {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 20px;
        height: 20px;
        background: #718280;
        border-radius: 50%;
      }
    }
  }

  .creepz-layout-wrapper {
    flex-wrap: wrap;
  }

  .staking-stat-wrapper {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    &.leaderboard {
      padding: 20px 0px;
    }

    .card {
      width: 30%;

      .staking_subtitle {
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #8dff1f;
        text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
        font-family: TerminusBold, sans-serif;
      }

      .details-input {
        width: 100%;
        background: #8dff1f;
        box-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
        font-family: Terminus, sans-serif;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #0b302b;
        text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);

        &.leaderboard {
          display: flex;
          justify-content: space-between;
          padding: 3px 5px;
        }
      }
    }
  }

  .army-title {
    font-family: Terminus, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #8dff1f;
    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .staked-creeps-wrapper {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 32px;

    &.leaderboard {
      gap: 20px;
      justify-content: space-around;
      padding-bottom: 30px;
    }

    .staked-creep-card {
      width: 70px;

      &.leaderboard {
        width: 30%;
      }

      .item__data {
        color: #8dff1f;
        display: flex;
        justify-content: space-between;
      }

      .creep-image {
        width: 70px;
        height: 70px;
        margin-bottom: 7px;
        position: relative;
        border-radius: 20px;

        &.leaderboard {
          width: 100%;
          height: auto;
        }

        img {
          border-radius: 5px;
        }
        .name-change__creepz {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: transparent;
          position: absolute;
          top: 0px;
          cursor: pointer;
          border-radius: 5px;

          &:hover {
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }

        .staking_icon {
          position: absolute;
          left: 3px;
          top: 0px;
          width: 10px;
          height: 10px;

          &.leaderboard {
            left: auto;
            right: 5px;
            top: 5px;
            width: 45px;
            height: 45px;

            .counter__container {
              position: relative;
              text-align: center;

              div {
                width: auto;
                height: 16px;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                color: #000;
                font-size: 12px;
              }
            }
          }
        }
      }
      .yield-text {
        letter-spacing: -0.04em;
        color: #8dff1f;
        text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
        font-family: Terminus, sans-serif;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          margin-right: 7px;
        }
      }
    }
  }
}

.mobile-buttons {
  display: none;
}

.overlord-link {
  position: fixed;
  cursor: pointer;
  z-index: 99;
  bottom: 15px;
  right: 15px;
  width: 200px;
  padding: 10px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.2);

  @media (max-width: 800px) {
    left: 0;
    right: 0;
    margin: auto;
  }
}
.header {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  height: auto;
  //max-height: 100%;
  //min-height: 15%;
  position: relative;
  // margin: 30px 0px 40px 0px;

  &__logo {
    max-width: 20%;
    height: 135px;
    margin-top: -40px;
    margin-left: 30px;
    transform: rotate(15deg);
  }

  &__menu-mobile {
    display: none;
  }

  &__nav {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex: 2;
    flex-direction: row;
    align-items: center;

    &-mobile {
      display: none;
    }

    &-item,
    &-item a {
      text-align: center;
      font-family: TerminusBold, sans-serif;
      font-size: 18px;
      margin-right: 25px;
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;
      color: #fff;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__buttons {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 5px;
  }

  &__button {
    height: 33px;
    width: 33px;
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  &__main {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    height: 83%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    position: relative;
    padding-top: 120px;

    .floating-item {
      position: absolute;

      &.item-1 {
        width: 15%;
        left: 8%;
        top: 14%;
        animation: floating 10s infinite linear;
      }

      &.item-2 {
        width: 6%;
        left: 27%;
        top: 12%;
      }

      &.item-3 {
        width: 16%;
        left: 3%;
        top: 33%;
        animation: float 10s infinite linear;
      }

      &.item-4 {
        width: 12%;
        right: 5%;
        top: 30%;
        animation: floating 5s infinite linear;
      }

      &.item-5 {
        width: 10%;
        right: 15%;
        top: 30%;
        animation: light 30s infinite linear;
      }

      &.item-6 {
        width: 20%;
        right: 15%;
        top: 10%;
        animation: float 10s infinite linear;
      }

      &.item-7 {
        width: 10%;
        right: 5%;
        top: 15%;
        animation: light 45s infinite linear reverse;
      }
    }

    &-map {
      position: relative;
      max-width: 1300px;
      margin-top: 220px;
      margin: 0 auto;
      margin-top: -390px;
      z-index: 99;
      background: radial-gradient(#a3f3ff, transparent 71%);

      .overlie {
        border-radius: 50px;
        position: absolute;
        display: flex;
        color: transparent;
        justify-content: center;
        align-items: center;

        &:hover {
          color: #fff;
          background: rgba(244, 67, 54, 0.2);
          box-shadow: 2px 2px 20px 10px rgba(244, 67, 54, 0.2);
        }

        &.active {
          &:hover {
            background: rgba(141, 255, 31, 0.2);
            box-shadow: 2px 2px 20px 10px rgba(141, 255, 31, 0.2);
            cursor: pointer;
            animation: none;
          }
        }

        &.creepHouse-cursor {
          width: 19%;
          height: 27%;
          left: 40%;
          top: 34%;
        }

        &.shapeshiters-cursor {
          width: 15%;
          height: 20%;
          left: 27%;
          top: 17%;
          // background: rgba(141,255,31,0.2);
          // box-shadow: 2px 2px 20px 10px rgba(141, 255, 31, 0.1);
          // animation: pulse 2s infinite;
        }

        &.staking-cursor {
          width: 12%;
          height: 18%;
          left: 8%;
          top: 24%;
        }

        &.lords-cursor {
          width: 5%;
          height: 11%;
          left: 33%;
          top: 40%;
        }

        &.shards-cursor {
          width: 5%;
          height: 11%;
          left: 61.2%;
          top: 40%;
          // background: rgba(213, 124, 224, 0.3);
          // box-shadow: 2px 2px 20px 10px rgba(213, 124, 224, 0.3);
          // animation: pulse-shard 1s infinite;
        }

        &.reptilian-cursor {
          width: 15%;
          height: 20%;
          left: 10%;
          top: 47%;
        }

        &.saloon-cursor {
          width: 15%;
          height: 20%;
          left: 62%;
          top: 57%;
        }

        &.dimential-cursor {
          width: 14%;
          height: 18%;
          left: 29%;
          top: 61%;
          // background: rgba(141, 255, 31, 0.2);
          // box-shadow: 2px 2px 20px 10px rgba(141, 255, 31, 0.1);
          // animation: pulse 1s infinite;
        }

        &.mystery-cursor {
          width: 12%;
          height: 16%;
          left: 80%;
          top: 44%;
        }

        &.temple-cursor {
          width: 18%;
          height: 18%;
          left: 63%;
          top: 18%;
          // background: rgba(141,255,31,0.2);
          // box-shadow: 2px 2px 20px 10px rgba(141, 255, 31, 0.1);
          // animation: pulse 1s infinite;
        }
      }
    }

    &-globe {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      filter: drop-shadow(0 0 30px #9b5ddc);
      width: 900px;
    }

    &-lizard {
      position: relative;
      text-align: center;
      margin: 0 auto;
      width: 700px;
      height: 700px;

      img {
        position: absolute;
        margin: 0 auto;
        width: 430px;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.02);
        box-shadow: 2px 2px 20px 10px rgba(255, 255, 255, 0.01);
        // cursor: pointer;
        animation: creepzPulse 2s infinite;
      }

      .left-eye {
        width: 110px;
        position: absolute;
        left: 29%;
        top: 7%;
        z-index: 4;
      }

      .right-eye {
        transform: scaleX(-1);
        width: 110px;
        position: absolute;
        right: 29%;
        top: 7%;
        z-index: 4;
      }

      .left-hand {
        width: 350px;
        position: absolute;
        left: -10%;
        top: 30%;
        z-index: 6;
      }

      .right-hand {
        transform: scaleX(-1);
        width: 350px;
        position: absolute;
        right: -10%;
        top: 30%;
        z-index: 6;
      }
    }

    &-callout {
      position: absolute;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      bottom: 75%;
      left: 63%;
      z-index: 5;

      &-mobile {
        display: none;
      }
    }

    &-button {
      position: absolute;
      display: flex;
      width: 247px;
      height: 56px;
      bottom: 9%;
      left: 50%;
      z-index: 15;
      transform: translateX(-50%);
      background: url("../assets/intro/button-purple.svg") no-repeat center
        center / cover;
      cursor: pointer;

      span,
      a {
        margin: auto;
        font-size: 16px;
        text-transform: uppercase;
        color: #101820;
        font-weight: 700;
      }
    }

    &-progress {
      position: absolute;
      left: 50%;
      bottom: 21%;
      transform: translateX(-50%);
      width: 40.5%;
      min-width: 35%;
      height: 50px;
      background-color: #73878b;
      border: 1.75px solid #101820;
      border-radius: 44px;
      z-index: 15;
      padding: 4px;
      overflow: hidden;

      .progress {
        width: 100%;
        height: 35px;
        position: relative;
        left: 50%;
        top: 50%;
        background-color: rgba(3, 3, 3, 0);

        transform: translate(-50%, -50%);
        border-radius: 44px;
        padding: 7px 12px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        overflow: hidden;

        font-size: 22px;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 8px solid rgba(255, 255, 255, 0.25);
          border-radius: 44px;
          z-index: 1;
        }

        span,
        a {
          z-index: 100;
        }

        &__received {
          text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
        }

        &__inner {
          padding: 0 10px;
          font-size: 22px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          height: 35px;
          position: absolute;
          left: 0;
          top: 50%;
          background-color: #20baa8;
          transform: translate(0, -50%);
          border-radius: 44px;
          box-shadow: 0 0 40px rgba(255, 255, 255, 0.6);

          span {
            z-index: 100;
          }
        }
      }
    }
  }
}

// City

.city {
  background-color: #0f2347;
  position: relative;
  width: 100%;
  overflow: hidden;

  .mint_cards {
    max-width: 850px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }
  .city_section__panel {
    max-width: 379px;
    margin: 40px 10px;

    .popup__card {
      border: none;
    }

    .popup__subtitle {
      font-size: 16px;
    }

    .popup__progress {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      margin: 10px 0 20px;
    }
  }

  &__info {
    margin: 120px auto 0;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 0 auto;
    z-index: 20;

    &-title {
      margin: 110px auto 0px;
      width: 630px;
      text-transform: uppercase;
      font-family: "Amatic SC", cursive;
      @include shadowText(1.1px, #000);
      font-size: 65px;
      text-align: center;
    }

    &-text {
      margin: 25px auto 15px;
      line-height: 110%;
      width: 465px;
      font-size: 16px;
      z-index: 2;
      position: relative;
    }
  }

  .edge-nlo1 {
    left: 0;
    top: 200px;
    position: absolute;
  }

  .edge-nlo2 {
    right: 0;
    top: 500px;
    position: absolute;
  }

  .cloud-1,
  .cloud-2,
  .cloud-3 {
    position: absolute;
  }

  .cloud-1 {
    left: 10%;
    top: 10%;
    width: 140px;
    max-height: 50px;
    animation: cloud1 15s infinite ease 0.4s;

    &.mint {
      top: 50%;
    }
  }

  .cloud-2 {
    right: 8%;
    top: 17%;
    width: 160px;
    max-height: 55px;
    animation: cloud2 15s infinite ease 0.7s;

    &.mint {
      top: 40%;
    }
  }

  .cloud-3 {
    left: -5%;
    top: 25%;
    width: 309px;
    max-height: 102px;
    animation: cloud3 15s infinite ease 1s;
  }

  &__bg {
    position: relative;
    margin-top: -190px;

    .houses {
      position: relative;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      vertical-align: bottom;
      justify-content: space-between;
      margin-top: -200px;

      &-left {
        &__lvl-1 {
          width: auto;
          height: auto;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 15;
        }

        &__lvl-2 {
          width: auto;
          height: auto;
          position: absolute;
          left: 0;
          bottom: 285px;
          z-index: 2;
        }
      }

      &-right {
        &__lvl-1 {
          width: auto;
          height: auto;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 10;
        }

        &__lvl-2 {
          width: auto;
          height: auto;
          position: absolute;
          bottom: 245px;
          right: 0;
          z-index: 5;
        }

        &__lvl-3 {
          width: auto;
          height: auto;
          position: absolute;
          bottom: 440px;
          right: 0;
          z-index: 1;
        }
      }
    }

    .further-bg {
      &__left {
        position: absolute;
        z-index: 0;
        height: auto;
        width: auto;

        left: 10%;
        bottom: 13%;
      }

      &__right {
        position: absolute;
        z-index: 0;
        height: auto;
        width: auto;

        right: 10%;
        bottom: 12%;
      }
    }

    .creeps {
      &__black {
        position: absolute;
        height: auto;
        width: 520px;
        left: 17%;
        bottom: -20%;
        z-index: 15;
      }

      &__trump {
        position: absolute;
        height: auto;
        width: auto;
        right: 24%;
        bottom: -30px;
        z-index: 15;
        width: 450px;
      }
    }

    .nlo {
      position: absolute;
      width: 410px;
      height: 100%;
      top: 23%;
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: nlo 15s infinite ease 0.2s;
      left: -2%;
      z-index: 5;

      &__model {
        width: 160px;
        height: 65px;
        z-index: 30;
      }

      &__vector {
        margin-top: -10px;
        z-index: 14;
        width: 410px;
        height: 680px;
      }
    }

    .grey-block {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 17%;
      background-color: #3c3e41;
    }
  }
}

// Armoury

.armoury {
  padding-bottom: 40px;
  max-height: none !important;
  background: url("../assets/armoury/bg.jpeg") no-repeat center center / cover !important;

  .armory_titles {
    width: 80%;
  }

  .armory_title {
    font-size: 75px;
    margin-bottom: 75px;
  }
  .armoury__content {
    &-inner {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .inner-panel {
        min-width: 1015px;
        min-height: 443px;
        background: url("../assets/armoury/window.svg") no-repeat center center /
          cover;
        margin: 0 40px;
        position: relative;

        .light {
          width: 290px;
          height: 290px;
          background: url("../assets/armoury/green_light.svg") no-repeat center /
            cover;
          position: absolute;
          animation: light 3s infinite linear;
          top: -5%;

          &_right {
            right: 5%;
          }

          &_left {
            left: 5%;
          }
        }

        .ufo {
          width: 490px;
          height: 300px;
          background: url("../assets/armoury/ufo.svg") no-repeat center / cover;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .armoury__panels {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-evenly;
    position: relative;
    z-index: 2;
    margin-top: -80px;
    padding-bottom: 50px;

    .armoury_panel {
      max-width: 337px;
      background: #0f2320;
      border-radius: 44px;
      border: 1.75px solid #101820;
      box-shadow: 0px 0px 40px rgba(115, 255, 66, 0.36);
      min-height: 250px;
      padding: 4px;

      .inner {
        border: 8px solid rgba(242, 244, 248, 0.09);
        box-sizing: border-box;
        border-radius: 44px;
        min-height: 100%;
        padding: 25px;

        .text {
          margin: auto;
          color: #8dff1f;
          line-height: 120%;
          font-family: Terminus, Arial, sans-serif;
          font-size: 18px;
          letter-spacing: -0.04em;
          // text-align: center;
          font-weight: 400;
          text-shadow: 0 1px 9px #73ff42;
        }

        div.title {
          font-size: 40px;
          margin-bottom: 20px;
          text-align: center;
        }

        div.subtitle {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// rarity

.rarity {
  padding-bottom: 115px;
  max-height: 940px;
  height: auto;
  width: 100%;
  position: relative;
  z-index: 1;
  background: url("../assets/rarity/bg.jpeg") no-repeat center center / cover !important;

  .lizard {
    position: absolute;
    top: 80px;
    left: 50px;
  }
  .rarity-background {
    background: url("../assets/rarity/pipes-bg.svg");
    background-position: center;
    background-repeat: repeat-x;

    .rarity-container {
      max-width: 786px;
      margin: 0 auto;
      background: #0f2320;
      /* Colour/Black */

      border: 1.75px solid #101820;
      /* Terminal BG Glow */

      box-shadow: 0px 0px 40px rgba(115, 255, 66, 0.36);
      border-radius: 44px;
      padding: 4px;

      .rarity-inner {
        width: 100%;
        height: 100%;
        border: 8px solid rgba(242, 244, 248, 0.09);
        box-sizing: border-box;
        border-radius: 44px;
        padding: 40px 0;

        .rarity-desktop {
          text-align: right;
        }

        .rarity-mobile {
          display: none;
        }
      }
    }
  }
}

.news {
  width: 100%;
  height: 60px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  vertical-align: center;
  overflow: hidden;
  flex-direction: row;
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  white-space: nowrap;

  a {
    text-decoration: none;
    color: #fff;
  }
  span {
    display: inline-block;
    margin-left: 30px;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -3px;
      width: 3px;
      height: 3px;
      background-color: #fff;
      border-radius: 50%;
    }
  }

  &__line {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    vertical-align: center;
    animation: news $speed-news infinite linear;
  }

  &__text {
    margin: 0 auto;
    //min-width: 100%;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    vertical-align: center;
    white-space: nowrap;

    span {
      white-space: nowrap;
      margin-right: 15px;
      margin-left: 15px;
      position: relative;
    }
  }

  &__point {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
  }
}

.interlayer {
  width: 100%;
  position: relative;
  z-index: 15;
  overflow: hidden;

  &__blocks {
    //height: 60px;
    height: 60px;
    z-index: 20;
    margin-top: -15px;
    margin-bottom: -15px;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 50%;
      width: 100vw;
      background-color: #0b302b;
      z-index: 1;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;

      &.green {
        background: url("../assets/interlayers/blocks-green.svg") no-repeat
          center center / cover;
      }

      &.blue {
        background: url("../assets/interlayers/blocks-blue.svg") no-repeat
          center center / cover;
      }
    }
  }

  &__stone {
    height: 84px;
    background-color: #101820;
    border-bottom: 1.75px solid #101820;
    overflow: hidden;

    &-img {
      position: absolute;
      bottom: 0;
      left: 0;
      min-width: 100%;
      height: 77px;
      background: url("../assets/interlayers/asphalt.svg") no-repeat center
        center / cover;
    }
  }
}

.progress_bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  .bar {
    width: 12px;
    height: 21px;
    background: #8dff1f;
    opacity: 0.2;
    margin: 0 2px;

    &.active {
      opacity: 1;
    }
  }

  span {
    margin: 0px 10px;
    font-family: Terminus, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.04em;

    /* Colours/Bright Green */

    color: #8dff1f;

    /* Terminal */

    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
  }

  &.lamex {
    .bar {
      background: #64f7f7;
    }
    span {
      color: #64f7f7;
      text-shadow: 0px 1px 9px #64f7f773;
    }
  }

  &.id {
    justify-content: space-around;
    .bar {
      background: #f764c6;
    }
    span {
      color: #f764c6;
      text-shadow: 0px 1px 9px #f764c6;
    }
  }
}

.statistic {
  width: 100%;
  height: auto;
  padding: 90px 0 100px;
  background: url("../assets/statistic/bg.jpg") no-repeat $color-blue-1 center
    center/cover;
  position: relative;

  &__pipe {
    position: absolute;
    left: 35px;
    top: -25px;
    z-index: 1;
    width: 100%;
  }

  &__pipe-lower {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    height: 370px;
    width: 50%;
  }

  &__wrapper {
    width: inherit;
    height: inherit;
  }

  &__gallery {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex-shrink: 0;
    //overflow: hidden;
    overflow: visible;
    margin: 0 0 0 55px;
    position: relative;
    z-index: 9;

    * {
      z-index: 5;
    }

    .gallery__pipe {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;

      &.lower {
        top: 25%;
        left: -10%;
        transform: none;
        min-width: 110vw;
      }
    }

    &-cards {
      width: auto;
      min-width: 140px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      vertical-align: center;
      margin-right: 25px;
      overflow: hidden;
    }

    &-card {
      margin-right: 25px;
      display: flex;
      width: 340px;
      min-width: 340px;
      height: 340px;
      border-radius: 10%;
      border: 2px solid $color-border-card;
      position: relative;

      &-decoration {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        img {
          position: absolute;
          height: auto;
        }
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 12px;
        right: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        height: 4px;
        width: 4px;
        border-radius: 50%;
      }

      &.gif {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        vertical-align: center;
        border-radius: 15px;
        min-width: 140px;
        width: 140px;
        height: 140px;

        &:nth-child(1) {
          margin-bottom: 30px;
        }

        &:before {
          bottom: 6px;
          right: 10px;
          background-color: rgba(255, 255, 255, 0.5);
          height: 2px;
          width: 2px;
          border-radius: 50%;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 3px;
          left: 10px;
          height: 4px;
          width: 115px;
          background-color: rgba(25, 27, 30, 0.11);
          border-radius: 30px;
        }

        .card__photo {
          margin: auto;
          width: 120px;
          height: 120px;
        }
      }

      .card__photo {
        margin: auto;
        width: 280px;
        height: 280px;
        border-radius: 20px;
      }

      .gallery-border-effect {
        width: 100%;
        height: 100%;
        position: absolute;

        .upper-line {
          position: absolute;
          left: 20px;
          top: 4px;
          width: 270px;
          height: 15px;
          border-radius: 70px;
          display: flex;

          .upper-inner-line {
            position: absolute;
            left: 60px;
            border-radius: 70px;
            top: 5px;
            width: 200px;
            height: 6px;
          }
        }

        .lower-line {
          position: absolute;
          left: 20px;
          bottom: 5px;
          width: 270px;
          height: 15px;
          border-radius: 70px;
          display: flex;

          .lower-inner-line {
            position: absolute;
            left: 19px;
            border-radius: 70px;
            top: 5px;
            width: 200px;
            height: 6px;
          }
        }
      }
    }
  }

  &__info {
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    &-title {
      font-family: "Amatic SC", cursive;
      font-weight: 700;
      font-size: 70px;
      margin: 0 auto;
      width: 50%;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      z-index: 15;
    }

    &-panels {
      display: flex;
      width: 90%;
      height: 300px;
      margin: auto;
      overflow: hidden;
      z-index: 10;

      .statistic__panel-title {
        white-space: nowrap;
      }
    }

    &-subtitle {
      font-size: 34px;
      text-align: center;
      font-family: "Amatic SC", cursive;
      font-weight: 700;
      margin: 5px auto 0;
      text-transform: uppercase;
      position: relative;
      z-index: 15;
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      justify-content: space-around;
      margin-bottom: 60px;

      .statistic__info__button {
        // background: url('../assets/statistic/button.svg') no-repeat center center / cover;
        height: 56px;
        display: flex;
        width: 250px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        margin: 0px 15px 60px;

        span {
          font-family: Terminus, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          /* identical to box height, or 100% */

          text-align: center;
          letter-spacing: -0.04em;
          text-transform: uppercase;

          /* Colour/Black */

          color: #101820;
        }
      }
    }

    &-additional-panels {
      height: 200px;
      width: auto;
      padding: 0 30px;
      display: flex;
      flex-flow: row nowrap;
      overflow: hidden;
      vertical-align: center;
      align-items: center;
      position: relative;
      margin: 0 auto;
      justify-content: space-between;

      &:after {
        content: "";
        position: absolute;
        left: 47.5%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 82%;
        height: 1em;
        background: url("../assets/statistic/vector.png") no-repeat center
          center / cover;
      }
    }
  }

  &__additional-panel {
    z-index: 10;
    color: $color-green-panel;
    box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);
    border-radius: 42px;
    white-space: nowrap;
    margin-right: 15px;
    width: auto;
    padding: 0 20px 0 20px;
    text-transform: uppercase;
    border: 1.75px solid #101820;
    text-align: center;
    height: 40px;
    background-color: #0f2320;
    font-size: 16px;
    display: flex;
    transition: box-shadow 0.1s linear;

    .wrapper {
      position: relative;
    }

    //&:first-child {
    //  margin-left: 100px;
    //}
    //
    //&:last-child {
    //  margin-right: 100px;
    //}

    &:hover {
      box-shadow: 0 0 50px rgba(115, 255, 66, 0.5);
    }

    span {
      margin: auto;
      text-shadow: 0 1px 9px #8dff1fff;
    }
  }

  &__panel {
    display: flex;
    font-family: "Amatic SC", cursive;
    font-size: 87px;
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
    margin-right: 50px;
    margin-top: 60px;
    flex: 1;
    border-radius: 44px;
    border: 1.75px solid $color-border-panel;
    height: 170px;
    background-color: $color-bg-panel;
    padding: 4px;
    position: relative;

    &:last-child {
      .wrapper {
        .statistic__panel-title {
          position: relative;
        }
      }
    }

    .wrapper {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      margin: auto;
      border-radius: 44px;
      border: 8px solid rgba(255, 255, 255, 0.24);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-title {
      width: 100%;
      margin: 5px auto 0;
      font-weight: 700;
      letter-spacing: -0.04em;
      text-align: center;
      @include shadowText(1.75px, #000);
    }

    &-subtitle {
      font-size: 16px;
      font-family: Terminus, sans-serif;
      color: #101820;
      margin: 4px auto;
      text-align: center;
      width: 60%;
      letter-spacing: -0.04em;
    }

    &:nth-child(1) {
      margin-left: 60px;
    }

    &:last-child {
      margin-right: 60px;
    }
  }
}

.plan {
  width: 100%;
  height: 3300px;
  background: url("../assets/plan/bg.jpg") no-repeat center center / cover;
  position: relative;

  .decor-6 {
    width: 115px;
    position: absolute;
    left: -92px;
    top: 70px;
  }
  .plan__upper-pipe-bg {
    position: absolute;
    width: 50%;
    height: auto;
    right: 0;
    top: 15px;

    &.mobile {
      display: none;
    }
  }

  &__title {
    position: relative;
    z-index: 15;
    font-family: "Amatic SC", cursive;
    font-size: 87px;
    text-align: center;
    text-shadow: 1.75px 0 1.75px #000, 0 1.75px 1.75px #000,
      -1.75px 0 1.75px #000, 0 1.75px 1.75px #000;
    margin-top: 80px;
  }

  &__map {
    height: auto;
    width: 100%;
    margin: 95px auto 0;
    position: relative;

    .rivet {
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #0b302b;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      border: 1.75px solid #101820;

      &.upper {
        top: -40px;
      }

      &.lower {
        bottom: -30px;

        &:after {
          background-color: #4e6f76;
        }
      }

      &:after {
        border: 1.75px solid #101820;
        border-radius: 50%;
        content: "";
        position: absolute;
        width: 75px;
        height: 75px;
        background-color: #425855;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__pipe {
    padding: 6px 5px;
    margin: 0 auto;
    height: 2700px;
    width: 45px;
    border: 1.75px solid #101820;
    background-color: #718280;
    border-radius: 44px;
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
    position: relative;
    z-index: 15;

    &-inner {
      height: 100%;
      width: 100%;
      border-radius: inherit;
      background-color: #20baa8;
      position: relative;
      z-index: 5;

      .inner {
        position: absolute;
        width: 100%;
        height: 50%;
        overflow: hidden;
        border-radius: 44px;
      }

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 44px;
        border: 8px solid rgba(255, 255, 255, 0.24);
      }
    }
  }

  &__step {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);

    &-progress {
      width: 120px;
      height: 75px;
      margin: 0 auto;
      position: relative;
      background-color: #73878b;
      border: $default-border;
      border-radius: 16px;
      padding: 3px 3px;

      &-value {
        position: relative;
        z-index: 15;
        font-size: 34px;
        font-family: "Amatic SC", cursive;
        font-weight: 700;
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }

      &-note {
        position: relative;
        z-index: 15;
        font-size: 15px;
        font-family: TerminusBold, sans-serif;
        color: #101820;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
      }

      .inner {
        width: 100%;
        height: 100%;
        background-color: $color-cyan;
        border-radius: 14px;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;

        &:before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          border-radius: 14px;
          border: 7px solid rgba(255, 255, 255, 0.24);
        }
      }
    }

    .plan__card {
      position: relative;
      margin: 15px auto;
      width: 570px;
      height: 255px;
      background: #fff;

      &-frame {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        z-index: 5;
        position: absolute;

        &-left {
          position: absolute;
          height: 100%;
          width: 50%;
          left: 0;
          top: 0;
          background: url("../assets/plan/cards/left-frame.jpg") no-repeat
            center center / cover;
        }

        &-right {
          position: absolute;
          height: 100%;
          width: 50%;
          right: 0;
          top: 0;
          background: url("../assets/plan/cards/right-frame.jpg") no-repeat
            center center / cover;
        }
      }

      &-decoration {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        img {
          position: absolute;
          height: auto;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      &-text {
        width: 30em;
      }

      &-title {
        text-transform: uppercase;
        font-weight: 700;
        color: #f2f4f8;
        width: 100%;
        font-family: "Amatic SC", cursive;
        font-size: 47px;
        text-shadow: 1.75px 0 1.75px #101820, 0 1.75px 1.75px #101820,
          -1.75px 0 1.75px #101820, 0 1.75px 1.75px #101820;
        text-align: center;
      }

      &-subtitles {
        color: #101820;
        font-size: 16px;
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        margin-top: 15px;
      }

      &-subtitle {
        text-align: center;
        margin: 0 auto 5px;
        width: auto;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.step-1 {
      top: 160px;

      .bg-pipes {
        width: 100vw;
        height: 127px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 150px;
        background: url("../assets/plan/bg-pipes.svg") no-repeat center center /
          cover;
        z-index: 1;
      }

      .plan-bg__pipe {
        width: 100px;
        height: 357px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        position: absolute;
        top: 35px;
        right: -230px;
        z-index: 20;

        .circle {
          width: 100px;
          height: 100px;
          border: $default-border;
          background-color: #0b302b;
          border-radius: 50%;
          display: flex;
          margin: 0 auto;

          &:before {
            content: "";
            margin: auto;
            width: 76px;
            border-radius: 50%;
            height: 76px;
            border: $default-border;
            background-color: #425855;
          }
        }

        .pipe {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 43px;
          height: 294px;
          border-radius: 44px;
          border: $default-border;
          background-color: #718280;
          display: flex;
          padding: 5px;

          .inner {
            width: 100%;
            height: 100%;
            margin: auto;
            border-radius: 44px;
            position: relative;
            overflow: hidden;
            background-color: #aeba20;
            box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 44px;
              border: 8px solid rgba(255, 255, 255, 0.24);
            }
          }
        }
      }

      .plan__card {
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../assets/plan/cards/card-1.svg") no-repeat center
          center / cover;
        z-index: 4;

        &-text {
          z-index: 10;
        }

        &-decoration {
          .decor-1 {
            top: -7px;
            left: 30px;
            width: 105px;
            height: auto;
            transform: rotate(6deg);
          }

          .decor-2 {
            top: -10px;
            left: -72px;
            width: 190px;
            transform: rotate(-40deg);
            height: auto;
          }

          .decor-3 {
            top: 10px;
            right: -5px;
            width: 80px;
            transform: scaleX(-1) rotate(-30deg);
            height: auto;
          }

          .decor-4 {
            bottom: -38px;
            left: 50%;
            transform: translateX(-50%);
            min-width: 613px;
            z-index: 2;
            height: auto;
          }

          .decor-5 {
            display: none;
          }
        }
      }
    }

    &.step-2 {
      top: 625px;

      .plan__card {
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../assets/plan/cards/card-2.svg") no-repeat center
          center / cover;

        &-subtitles {
          color: #fff;
        }

        &-subtitle:before {
          background-color: #fff;
        }

        &-decoration {
          .decor-1 {
            right: -33px;
            top: 50%;
            transform: translateY(-50%);
            width: 130px;
            height: auto;
          }
        }
      }
    }

    &.step-3 {
      top: 1090px;

      .bg-pipes {
        width: 100vw;
        height: 127px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 170px;
        background: url("../assets/plan/bg-pipes.svg") no-repeat center center /
          cover;
        z-index: 1;
      }

      .wrapper {
        height: 255px;
        bottom: 0;
        position: relative;
        z-index: 10;
      }

      .plan__card {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 29px;
        background: url("../assets/plan/cards/card-3.svg") no-repeat center
          center / cover;
        box-shadow: 0 0 0 13px #101820, 0 0 143px rgba(255, 255, 255, 1);
        animation: blowTV ease 2s;
        animation-iteration-count: 5;
        border-radius: 14px;
        z-index: 10;

        &-decoration {
          .decor-1 {
            right: -68px;
            top: 50%;
            transform: translateY(-50%);
            width: 55px;
            height: auto;
            z-index: 3;
          }
        }

        &-title {
          line-height: 52px;

          span {
            display: inline;
            background-color: #000;
            box-shadow: -10px 0 0 #000, 10px 0 0 #000;
          }
        }
      }
    }

    &.step-4 {
      top: 1570px;

      .plan__card {
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../assets/plan/cards/card-4.svg") no-repeat center
          center / cover;
        margin-top: 29px;
        box-shadow: 0 0 0 13px #fff;
        border-radius: 14px;

        &-decoration {
          .decor-1 {
            top: -100px;
            right: -100px;
            width: 225px;
            height: auto;
            animation: settelite 5s infinite;
          }

          .decor-2 {
            top: 50%;
            transform: translateY(-50%);
            left: -65px;
            width: 65px;
            height: auto;
            z-index: 3;
          }

          .decor-3 {
            top: 50%;
            transform: translateY(-50%);
            right: -65px;
            width: 65px;
            height: auto;
            z-index: 3;
          }

          .decor-4 {
            top: 170px;
            left: -56px;
            width: 18px;
            height: auto;
            z-index: 1;
          }

          .decor-5 {
            top: 170px;
            right: -56px;
            width: 18px;
            height: auto;
            z-index: 1;
          }

          .right-fire {
            position: absolute;
            left: -100px;
            top: 45px;
            width: 140px;
            height: auto;
            z-index: 1;
          }

          .left-fire {
            position: absolute;
            right: -135px;
            top: 45px;
            width: 140px;
            height: auto;
            z-index: 1;
          }
        }

        &-subtitles {
          color: #fff;
        }

        &-subtitle:before {
          background-color: #fff;
        }
      }
    }

    &.final {
      top: 2150px;
      width: 850px;
      height: 380px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .wrapper {
        position: relative;
        width: 400px;
        height: 100%;
        margin: 0 -35px;

        .creep {
          position: absolute;
          left: -200px;
          top: -90px;
          z-index: 2;
        }
      }

      .vessel {
        border: 1.75px solid #101820;
        border-radius: 56px;
        background-color: #fff;
        position: relative;
        z-index: 4;
        padding: 4px;

        &.left,
        &.right {
          width: 260px;
          height: 308px;
          padding: 4px;
          z-index: 1;

          .inner {
            backdrop-filter: blur(2px);
            z-index: 10;
            overflow: hidden;
          }

          .coin {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
          }
        }

        &.center {
          width: 100% !important;
          height: 100% !important;
          margin: 0 !important;
          z-index: 5;
          padding: 4px;
          position: relative;

          .upper-rivet {
            position: absolute;
            width: 184px;
            height: 70px;
            transform: translateX(-50%);
            left: 50%;
            top: -71px;
          }

          .lower-rivet {
            position: absolute;
            width: 184px;
            height: 70px;
            transform: translateX(-50%);
            left: 50%;
            bottom: -71px;
          }

          .vessel__button {
            position: absolute;
            bottom: -17px;
            left: 50%;
            transform: translateX(-50%);
            width: 270px;
            height: 36px;
            border-radius: 9px;
            border: 1.75px solid #101820;
            background-color: #fa4b28;
            box-shadow: 0 0 40px #ff4040;
            display: flex;
            padding: 4px;
            cursor: pointer;
            animation: blowButtonCentralVessel 4s ease infinite;

            .progress {
              margin: auto 0;
              height: 100%;
              width: 74%;
              background-color: rgba(242, 244, 248, 0.31);
              border-radius: 4px;
              display: flex;
            }

            span {
              margin: auto auto auto 8px;
              font-size: 16px;
              font-family: TerminusBold, sans-serif;
              color: #101820;
              white-space: nowrap;
              text-transform: uppercase;
              text-align: center;
            }
          }

          .inner {
            background-color: #20baa8;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;

            .vessel__title {
              width: 90%;
              text-shadow: 1.75px 0 1.75px #3bbfbe, 0 1.75px 1.75px #3bbfbe,
                -1.75px 0 1.75px #3bbfbe, 0 1.75px 1.75px #3bbfbe,
                0 1.44px 13px rgba(158, 238, 255, 0.72);
              text-align: center;
              text-transform: uppercase;
              font-size: 100px;
              letter-spacing: 5px;
              font-family: "Amatic SC", cursive;
              font-weight: 700;
              position: relative;
              z-index: 10;
            }

            .vessel__subtitle {
              width: 90%;
              margin-top: 15px;
              text-shadow: 0 1.19px 11px rgba(158, 238, 255, 0.72);
              font-size: 24px;
              text-align: center;
              font-family: Terminus, Arial, sans-serif;
              color: #101820;
              position: relative;
              z-index: 10;
            }
          }
        }

        &.left {
          position: relative;

          .inner {
            background-color: rgba(32, 130, 186, 0.82);

            .vessel__divisions {
              height: 70%;
              left: 5px;
            }
          }

          .left-river {
            width: 112px;
            height: 132px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -112px;
          }

          .lower-river {
            width: 164px;
            height: 125px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -119px;
          }
        }

        &.right {
          .right-river {
            width: 112px;
            height: 132px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -112px;
          }

          .lower-river {
            width: 164px;
            height: 125px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -119px;
          }

          .inner {
            background-color: rgba(174, 186, 32, 0.82);
          }
        }

        .inner {
          width: 100%;
          height: 100%;
          border-radius: 50px;
          position: relative;
          border: 6px solid rgba(255, 255, 255, 0.24);

          .vessel__divisions {
            position: absolute;
            height: 77%;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.answers {
  background: url("../assets/answers/bg.jpg") no-repeat center center / cover;
  //height: 1540px;
  height: auto;
  padding-bottom: 100px;
  width: 100%;
  position: relative;

  .bg__pipe-1 {
    position: absolute;
    height: 100%;
    left: 100px;
    top: 0;
  }

  .bg__pipe-3 {
    position: absolute;
    height: 90px;
    right: -45px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__container {
    width: 60%;
    margin: 0 auto;
    height: auto;
  }

  &__title {
    margin-top: 70px;
    font-size: 87px;
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    text-shadow: 2.24px 0 2.24px #101820, 0 2.24px 2.24px #101820,
      -2.24px 0 2.24px #101820, 0 2.24px 2.24px #101820;
    position: relative;
    z-index: 15;
  }

  &__items {
    margin-top: 40px;
    display: flex;
    flex-flow: column nowrap;
  }

  &__item {
    height: 120px;
    width: 85%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 23px;

    &.active {
      height: auto;

      .question__button {
        background-color: #9b5ddc;
        cursor: pointer;

        &:hover {
          background-color: darken(#9b5ddc, 5);
        }

        &-inner:before {
          transform: translate(-50%, -50%) rotate(135deg);
        }

        &-inner:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      .answer {
        //top: 0;
        transform: translateY(0);
        opacity: 1;

        span {
          display: inline-block;
        }

        &:before {
          display: block;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-lumps {
      min-width: 23px;
      display: flex;
      flex-flow: column nowrap;

      img {
        max-width: 100%;
        height: auto;
        margin: 0 auto 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .answer {
      //display: flex;
      font-family: TerminusBold, sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto;
      width: 95%;
      background-color: #0b302b;
      border-bottom-right-radius: 14px;
      border-bottom-left-radius: 14px;
      position: relative;
      min-height: 120px;
      height: auto;
      padding: 34px 33px 33px 23px;
      border-bottom: $default-border;
      border-right: $default-border;
      border-left: $default-border;
      opacity: 0;
      //top: -120px;
      transform: translateY(-90%);
      transition: transform 0.2s linear;

      &:before {
        content: "";
        position: absolute;
        height: 12px;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.26);
        width: 100%;

        display: none;
      }
    }

    .wrapper {
      position: relative;

      .bg__pipe-2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -9rem;
      }
    }

    .question {
      display: flex;
      flex-flow: row nowrap;
      background-color: #0b302b;
      min-height: 114px;
      height: auto;
      width: 100%;
      padding: 18px 0;
      align-items: center;
      border-radius: 15px;
      border: $default-border;
      position: relative;
      z-index: 10;
      cursor: pointer;

      &__pipe {
        position: absolute;
        left: -100px;
        top: 0;
        z-index: 5;
        height: auto;
        max-width: 30%;
      }

      &__button {
        min-width: 43px;
        min-height: 43px;
        border-radius: 50%;
        border: $default-border;
        background-color: #8dff1f;
        position: absolute;
        right: -21.5px;
        transition: background-color 0.1s linear;
        cursor: pointer;

        &:hover {
          background-color: darken(#8dff1f, 5);
        }

        &-inner:after {
          content: "";
          position: absolute;
          width: 55%;
          height: 2px;
          left: 50%;
          top: 50%;
          background-color: $color-text-black-1;
          transform: translate(-50%, -50%) rotate(0deg);
        }

        &-inner:before {
          content: "";
          position: absolute;
          width: 2px;
          height: 55%;
          left: 50%;
          top: 50%;
          background-color: $color-text-black-1;
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }

      &__text {
        margin-right: 23px;
        width: 100%;
        min-height: 80px;
        height: auto;
        max-height: 100%;
        display: flex;
        font-family: TerminusBold, sans-serif;
        font-size: 25px;
        vertical-align: center;
        border-radius: 7px;
        background-color: rgba(255, 255, 255, 0.11);

        span {
          margin: auto 0 auto 20px;
        }
      }
    }
  }
}

.discord {
  max-height: 940px;
  height: auto;
  background: url("../assets/discord/bg.jpg") no-repeat center center / cover;
  width: 100%;
  position: relative;
  z-index: 1;

  &__titles {
    width: 40%;
    display: flex;
    flex-flow: column nowrap;
    margin: 70px auto 50px;
    text-align: center;
  }

  &__title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 70px;
    text-align: center;
    @include shadowText(1.75px, $color-text-black-1);

    span {
      font-size: 34px;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
  }

  .discord__content {
    overflow: visible;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-height: 100%;
    height: auto;
    margin: 50px auto 0;

    .discord__pipe {
      width: 100px;
      height: 304px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      position: relative;

      &.cyan {
        .inner {
          background-color: #20baa8;
          position: relative;
          overflow: hidden;
        }
      }

      &.yellow {
        .inner {
          background-color: #aeba20;
          position: relative;
          overflow: hidden;
        }
      }

      .circle {
        width: 100px;
        height: 100px;
        border: $default-border;
        background-color: #0b302b;
        border-radius: 50%;
        display: flex;
        margin: 0 auto;

        &:before {
          content: "";
          margin: auto;
          width: 80%;
          height: 80%;
          border-radius: 50%;
          border: $default-border;
          background-color: #425855;
        }
      }

      .pipe {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 37%;
        height: 80%;
        border-radius: 44px;
        border: $default-border;
        background-color: #718280;
        display: flex;
        padding: 5px;

        .inner {
          width: 100%;
          height: 100%;
          margin: auto;
          border-radius: 44px;
          position: relative;

          .discord__logo {
            bottom: 0;
            position: absolute;

            &.logo-1 {
              left: 2px;
              animation: bubbleDiscord linear 10s infinite;
            }

            &.logo-2 {
              right: 3px;
              animation: bubbleDiscord linear 8s infinite;
            }
          }

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 44px;
            border: 8px solid rgba(255, 255, 255, 0.24);
          }
        }
      }
    }

    .discord__panels {
      overflow: visible;
      width: 70%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .discord__panel {
        width: 48%;
        min-height: 190px;
        max-height: 190px;
        background-color: #0f2320;
        padding: 4px;
        border-radius: 44px;
        border: $default-border;
        box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 30px;
        }

        .inner {
          height: 100%;
          width: 100%;
          border: 8px solid rgba(255, 255, 255, 0.09);
          border-radius: 44px;
          padding: 30px 40px;
          display: flex;

          span {
            margin: auto;
            color: #8dff1f;
            line-height: 100%;
            font-family: Terminus, sans-serif;
            font-size: 25px;
            text-align: center;
            text-shadow: 0 1px 9px #73ff42;
          }
        }
      }
    }
  }

  &__button {
    margin: 60px auto;
    // background: url('../assets/discord/button-purple.svg') no-repeat center center / cover;
    height: 56px;
    display: flex;
    width: 250px;
    cursor: pointer;

    a {
      color: #101820;
      font-family: TerminusBold, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      margin: auto;
      text-decoration: none;
    }
  }
}

.cursor {
  height: 22px;
  width: 12px;
  background-color: #8dff1f;
  box-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
  animation: cursor 1s linear infinite;
}

.creators {
  min-height: 2450px;
  width: 100%;
  background: url("../assets/creators/bg.jpg") no-repeat center center / cover;
  position: relative;

  .card__info {
    position: relative;
    z-index: 30;
    max-width: 222px;
    height: auto;
    max-height: 60px;
    border-radius: 42px;
    border: 1.75px solid #101820;
    background-color: #0b302b;
    box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);
    margin: -40px auto 0;
    padding: 7px 0 10px 0;

    &.creator {
      position: absolute;
      width: 222px;
      height: 60px;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -15px;
    }

    &-name {
      font-size: 25px;
      color: #8dff1f;
      text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
      text-align: center;
      margin: 0 auto 2px;
      text-transform: capitalize;
    }

    &-creator {
      font-size: 18px;
      font-family: TerminusBold, sans-serif;
      text-align: center;
      margin: 0 auto;
    }
  }

  .card__twitter {
    width: 100%;
    margin: 11px 0 0;
    display: flex;

    &.creator-twitter {
      margin: 20px 0 0;
      text-align: center;
    }

    a {
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .bg__pipe-3 {
    position: absolute;
    bottom: 0;
    left: -25px;
  }

  &__video {
    margin-top: 64px;
    position: relative;

    &-title {
      color: #f2f4f8;
      margin: 0 auto;
      font-size: 87px;
      font-family: "Amatic SC", cursive;
      font-weight: 700;
      @include shadowText(2.24px, #101820);
      text-align: center;
      position: relative;
      z-index: 15;
    }

    .wrapper {
      width: 60%;
      margin: 95px auto 0;
      max-height: 600px;
      position: relative;
      z-index: 10;

      .bg__pipe-1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100vw;
      }
    }

    &-player {
      width: 100%;
      max-height: 1800px;
      background-color: #5b5b5b;
      border-radius: 44px;
      border: 1.75px solid #000;
      padding: 20px;
      position: relative;
      z-index: 10;

      &:after {
        content: "";
        position: absolute;
        background: url("../assets/creators/video/logo.png") no-repeat center
          center / cover;
        left: 50%;
        top: 50%;
        width: 173px;
        height: 173px;
        transform: translate(-50%, -50%);
      }

      .video__player-pipe {
        position: absolute;
      }

      .video__player-pipe.left {
        width: 52px;
        height: auto;
        left: -52px;
        top: 15%;
        z-index: 5;
      }

      .video__player-pipe.right {
        width: 52px;
        height: auto;
        max-height: 70%;
        right: -52px;
        top: 50%;
        transform: translateY(-50%);
      }

      img.video__background {
        width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: 44px;
        border: 1.75px solid #000;
      }
    }

    &-lamps {
      position: absolute;
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      width: 28px;
      height: auto;
      right: 11%;
      bottom: 5px;

      .lamp.blue {
        background-color: rgba(0, 194, 255, 0.2);

        &.active {
          background-color: #00c2ff;
        }
      }

      .lamp.red {
        background-color: rgba(255, 0, 0, 0.2);

        &.active {
          background-color: #ff0000;
        }
      }

      .lamp {
        border: 1.75px solid #101820;
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }
  }

  &__team {
    padding-top: 100px;
    width: 100%;
    position: relative;

    .bg__pipe-2 {
      position: absolute;
      top: -170px;
      left: 7%;
      z-index: 1;
    }

    &-title {
      position: relative;
      z-index: 10;
      color: #f2f4f8;
      font-size: 87px;
      margin: 0 auto;
      font-family: "Amatic SC", cursive;
      font-weight: 700;
      @include shadowText(2.24px, #101820);
      text-align: center;
    }

    &-cards {
      position: relative;
      z-index: 10;
      margin: 120px auto 0;
      width: 75%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
    }

    &-card {
      width: 30%;
      height: auto;
      margin-bottom: 30px;

      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        margin-bottom: 0;
      }

      .card__image {
        width: 340px;
        height: 340px;
        margin: 0 auto;
        padding: 27px;
        display: flex;
        position: relative;
        background: url("../assets/creators/border-card.png") no-repeat center
          center / cover;

        &-status {
          position: absolute;
          right: 37px;
          top: 35px;
          text-transform: uppercase;
          font-family: TerminusBold, sans-serif;
          font-size: 16px;
        }

        .img {
          position: relative;
          height: 100%;
          width: 100%;
          margin: auto;
        }

        img {
          border-radius: 20px;
        }
      }
    }
  }
}

.footer {
  // height: 800px;
  width: 100%;
  background-color: #0b302b;
  position: relative;

  .footer__copy {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 30%;
      text-align: center;
    }
  }

  .bg__pipe-1 {
    position: absolute;
    left: -30px;
    top: 14px;
  }

  .bg__pipe-2 {
    height: 100%;
    position: absolute;
    left: 163px;
    top: 0;
  }

  &__panel {
    margin: 100px auto;
    width: 1050px;
    height: 540px;
    border-radius: 56px;
    border: 1.75px solid #101820;
    background-color: #fff;
    padding: 4px;
    box-shadow: 0 0 60px rgba(255, 119, 233, 0.55);
    position: relative;

    &-pipes {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      .pipe {
        width: 112px;
        position: absolute;

        &-1 {
          left: -112px;
          top: 22%;
        }

        &-2 {
          left: -112px;
          bottom: 22%;
        }

        &-3 {
          right: -112px;
          top: 22%;
        }

        &-4 {
          right: -112px;
          bottom: 22%;
        }
      }
    }

    &-text {
      background-color: #20baa8;
      border-radius: 50px;
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      border: 6px solid rgba(255, 255, 255, 0.24);
      overflow: hidden;

      span {
        text-shadow: 0 1.44px 13px rgba(158, 238, 255, 0.72);
        margin: auto;
        width: 50%;
        text-align: center;
        font-family: "Amatic SC", cursive;
        font-size: 105px;
        font-weight: 700;
      }
    }

    &-button {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 247px;
      height: 56px;
      background: url("../assets/footer/button-purple.svg") no-repeat center
        center / cover;
      display: flex;
      cursor: pointer;

      span {
        margin: auto;
        color: #101820;
        font-family: TerminusBold, sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        position: relative;
        z-index: 25;
      }
    }
  }

  &__info {
    height: 60px;
    width: 100%;
    background: url("../assets/footer/bg-info.svg") no-repeat center center /
      cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;

    &-author,
    &-contract {
      text-transform: uppercase;
      margin: auto 0;
      font-family: TerminusBold, sans-serif;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);

      a {
        text-decoration: none;
        color: inherit;
        font-size: inherit;
      }
    }

    &-logos {
      display: flex;

      a {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.popup {
  display: flex;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(17, 20, 51, 0.79);
  z-index: 99999;

  &.step-1 {
    .popup__panel {
      margin: auto;
      width: 465px;
      height: 245px;
    }

    .popup__success {
      width: 85%;
      font-size: 34px;
      height: auto;
      margin: auto;
    }
  }

  &__panel {
    position: relative;
    z-index: 250;
    margin: auto;
    width: 465px;
    background: #0b302b;
    border: 1.75px solid #101820;
    box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);
    border-radius: 44px;
    padding: 6px 5px;

    .card__info {
      position: absolute;
      z-index: 30;
      width: 250px;
      height: auto;
      max-height: 60px;
      border-radius: 42px;
      border: 1.75px solid #101820;
      background-color: #0b302b;
      box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);
      left: 0;
      right: 0;
      margin: -30px auto 0;
      padding: 7px 0 10px 0;
      color: #8dff1f;
      text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);

      &.card__info-staking {
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        text-transform: none;

        &.private-bank {
          background: #101820;
          border: 1.75px solid #8dff1f;
        }
      }

      &-name {
        font-size: 24px;
        text-align: center;
        margin: 0 auto 2px;
        text-transform: capitalize;
      }

      &-creator {
        font-size: 12px;
        font-family: Terminus, sans-serif;
        text-align: center;
        margin: 0 auto;
      }
    }

    &-wrapper {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      margin: auto;
      border-radius: 44px;
      border: 8px solid rgba(255, 255, 255, 0.09);
      z-index: 1;
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      padding: 0px 0px 30px;
    }
  }

  &__title {
    width: 85%;
    font-size: 30px;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -0.04em;
    color: #8dff1f;
    margin: 22px auto 0;
    text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
  }

  &__card {
    margin: 10px auto 0;
    width: 336px;
    height: 336px;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 19px;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    &.claim_select {
      cursor: pointer;
      position: relative;

      &:hover {
        transform: scale(1.03);
        transition: 0.1s;
      }

      .claim_text {
        position: absolute;
        margin: 0 auto;
      }
    }

    &.claim_select_unavailable {
      cursor: auto;

      &::after {
        content: "";
        z-index: 10;
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .image {
      width: 310px;
      box-sizing: border-box;
      margin: 0 auto;
      height: auto;
      overflow: hidden;
      border-radius: 19px;
    }
  }

  &__input {
    width: 321px;
    height: 36px;
    margin: 25px auto 0;
    position: relative;

    input {
      font-family: Terminus, sans-serif;
      height: 100%;
      width: 100%;
      text-align: center;
      color: #8dff1f;
      text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
      letter-spacing: -0.04em;
      font-size: 24.62px;
      background: #0f2320;
      border: 1.75px solid #101820;
      box-sizing: border-box;
      border-radius: 42px;
    }

    input.floor {
      color: #000;
      background: #9b5ddc;
      text-shadow: none;
    }

    input.pass {
      color: #000;
      background: #fff;
      text-shadow: none;
    }

    .plus,
    .minus {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #668368;
      border: 1.75px solid #101820;
    }

    .minus {
      left: 0;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 16px;
        background-color: #8dff1f;
        box-shadow: 0 1px 9px #73ff42;
      }
    }

    .plus {
      right: 0;

      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #8dff1f;
        box-shadow: 0 1px 9px #73ff42;
      }

      &:after {
        height: 2px;
        width: 16px;
      }

      &:before {
        height: 16px;
        width: 2px;
      }
    }

    .plus.floor,
    .minus.floor {
      background-color: #9b5ddc;

      &:after,
      &:before {
        background-color: #000;
        box-shadow: none;
      }
    }

    .plus.pass,
    .minus.pass {
      background-color: #fff;

      &:after,
      &:before {
        background-color: #000;
        box-shadow: none;
      }
    }
  }

  .mint_buttons {
    display: flex;
    flex-direction: row wrap;
    gap: 5%;
    width: 100%;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.75px solid #101820;
      box-sizing: border-box;
      border-radius: 8px;
      width: 45%;
      height: 32px;
      margin: 15px 0px;
      font-size: 16px;
      color: #0f2320;
      text-transform: uppercase;
      cursor: pointer;

      &.loomi {
        color: #fff;
        background: url("../assets/staking/illuminarti.svg") no-repeat;
        background-position-x: right;
        background-position-y: center;
        background-size: 15px 15px;
        justify-content: right;
        padding-right: 10px;
        cursor: auto;
      }
    }

    input.loomi {
      display: flex;
      padding-left: 35px;
      border: 1.75px solid #101820;
      box-sizing: border-box;
      border-radius: 8px;
      width: 45%;
      height: 32px;
      margin: 15px 0px;
      font-size: 16px;
      color: #0f2320;
      cursor: pointer;
      color: #fff;
      background: url("../assets/mystery/search.svg") no-repeat;
      background-position-x: 8px;
      background-position-y: center;
      background-size: 15px 15px;
      font-family: Terminus, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.04em;
      color: #8dff1f;
      padding-right: 10px;
      cursor: auto;

      &::placeholder {
        color: #8dff1f;
      }
    }

    div.loomi.tample {
      background: none;
      font-family: Terminus, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.04em;
      color: #8dff1f;
      justify-content: center;
      text-align: center;
      cursor: pointer;

      &.stats {
        width: 48%;
        cursor: auto;
      }
    }
  }

  &__choices {
    display: flex;
    flex-flow: row nowrap;
    width: 60%;
    margin: 14px auto 0;
  }

  &__choice {
    cursor: pointer;
    width: 87px;
    height: 34px;
    margin-right: 5px;
    background: rgba(0, 0, 0, 0.2);
    border: 1.75px solid #000000;
    border-radius: 9px;

    font-size: 24.62px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #8dff1f;
    text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);

    &:last-child {
      margin-right: 0;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #8dff1f;
    text-shadow: 0px 1px 9px rgba(115, 255, 66, 0.72);
    margin: 20px 0px;

    span {
      font-size: 14px;
    }

    &.loomi {
      font-size: 24px;
      margin: 10px 0px 10px;
    }
  }

  &__button {
    cursor: pointer;
    position: absolute;
    bottom: -23px;
    left: 50%;
    transform: translateX(-50%);
    // background: url('../assets/popup/button.svg') no-repeat center center / cover;
    display: flex;
    width: 247px;
    height: 56px;
    z-index: 4;

    a,
    span {
      margin: auto;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: #101820;
      text-decoration: none;
    }
  }

  &__button-close {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -20px;
    width: 64px;
    height: 64px;
    background-color: #fa4b28;
    border: 1.75px solid #101820;
    box-shadow: 0 0 71px rgba(255, 64, 64, 0.9);
    border-radius: 16px;
    padding: 8px;
    z-index: 3;

    .wrapper {
      height: 100%;
      width: 100%;
      background-color: rgba(242, 244, 248, 0.31);
      border-radius: 8px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 3px;
        height: 21px;
        left: 50%;
        top: 50%;
        background-color: #101820;
        transform: translate(-50%, -50%) rotate(-30deg);
      }

      &:before {
        content: "";
        position: absolute;
        width: 3px;
        height: 21px;
        left: 50%;
        top: 50%;
        background-color: #101820;
        transform: translate(-50%, -50%) rotate(30deg);
      }
    }
  }

  &__success {
    font-size: 34px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.06em;
    padding: 20px;
    color: #8dff1f;
  }
}

.pyramid {
  height: auto;
  width: 100%;
  background-color: #0f2320;
  overflow: hidden;
  padding-bottom: 90px;
  position: relative;

  .money {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;

    @media (max-width: 1000px) {
      display: none;
    }

    .money__item {
      max-width: 120px;
      height: auto;
      position: absolute;
      top: 0;
      animation: money 10s linear infinite;

      &-1 {
        left: -5px;
        animation-duration: 11s;
      }

      &-2 {
        left: 70px;
        animation-duration: 15s;
      }

      &-3 {
        left: 144px;
        animation-duration: 8s;
      }

      &-4 {
        left: 245px;
        animation-duration: 14s;
      }

      &-5 {
        left: 242px;
        animation-duration: 12s;
      }

      &-6 {
        left: 328px;
        animation-duration: 16s;
      }

      &-7 {
        right: 263px;
        animation-duration: 9s;
      }

      &-8 {
        right: 297px;
        animation-duration: 10s;
      }

      &-9 {
        right: 175px;
        animation-duration: 11s;
      }

      &-10 {
        right: 51px;
        animation-duration: 13s;
      }

      &-11 {
        right: 48px;
        animation-duration: 10.5s;
      }
    }
  }

  &__description {
    width: 340px;
    text-align: center;
    margin: 20px auto 46px;
  }

  &__title {
    font-family: "Amatic SC", cursive;
    font-size: 96px;
    text-align: center;
    width: 50%;
    margin: 80px auto 0;
    position: relative;
    z-index: 5;
  }

  &__figures {
    width: 80%;
    height: auto;
    margin: 200px auto 0;
    position: relative;
    z-index: 5;
  }

  &__figure {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: auto;
    width: 150px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    &.active {
      transform: translateX(-50%) scale(1.15);
    }

    &-button-2 {
      background: url("../assets/pyramid/button.svg") no-repeat center center /
        cover;
      width: 253px;
      height: 60px;
      margin: 36px auto 0;
      display: flex;
      outline: none;
      border: none;
      cursor: pointer;
      position: relative;
      z-index: 3;

      span {
        margin: auto;
        text-transform: uppercase;
        font-family: TerminusBold, sans-serif;
        font-size: 16px;
      }
    }

    &-name {
      color: #fff;
      font-family: "Amatic SC", cursive;
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 15px;
      margin-top: 15px;
      text-align: center;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1.75px;
      background-color: #101820;
    }
  }

  .figure {
    &-1 {
      height: 0;
      width: 320px;
      border-left: 110px solid transparent;
      border-right: 110px solid transparent;
      border-bottom: 180px solid #008600;
      transition: 0.2s;

      .pyramid__figure-name {
        margin-top: 55px;
      }

      .pyramid__figure-logo {
        position: absolute;
        top: -110px;
        left: 50%;
        transform: translateX(-42%);
        width: 175px;
        height: 165px;
        max-width: none;
        z-index: 10;
      }
    }

    &-2 {
      height: 0;
      width: 440px;
      border-bottom: 105px solid #008600;
      border-left: 60px solid transparent;
      border-right: 60px solid transparent;
      transition: 0.2s;
    }

    &-3 {
      height: 0;
      width: 560px;
      border-bottom: 105px solid #008600;
      border-left: 60px solid transparent;
      border-right: 60px solid transparent;
      transition: 0.2s;
    }

    &-4 {
      height: 0;
      width: 680px;
      border-bottom: 105px solid #008600;
      border-left: 60px solid transparent;
      border-right: 60px solid transparent;
      transition: 0.2s;
    }

    &-5 {
      height: 0;
      width: 800px;
      border-bottom: 105px solid #008600;
      border-left: 60px solid transparent;
      border-right: 60px solid transparent;
      transition: 0.2s;
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.footer,
.plan,
.statistic,
.creators,
.pyramid {
  .container {
    position: static;
  }
}

.mobile {
  display: none;
}
.desktop {
  display: block;
}

@import "media-new.scss";
