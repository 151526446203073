$section-inner-border: 8px solid rgba(255, 255, 255, 0.15);
$tab-menu-color: #452b6e;
$lamex-color: #64f7f7;
$dark-color: #101820;
$dark-purple-color: #291747;
$main-bg-color: #2e1556;
$light-purple: #755d9d;
$text-bg-color: #261736;
$red-color: #f8a494;
$id-color: #f764c6;
$green-color: #78f764;
$red-color: #ff1313;
$grey-color: #718280;

@mixin center-absolute {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@mixin imageSizing {
  width: 100%;
  border-radius: 8px;
  border: 2px solid $dark-color;
}

.tab-button {
  width: 100%;
  min-width: 175px;
  height: 36px;
  padding: 0px 16px;
  box-sizing: border-box;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  // margin-bottom: 15px;

  &.selector {
    position: relative;

    .selector-arrow {
      position: absolute;
      right: 20px;
      transform: rotate(180deg);

      &.active {
        transform: rotate(0deg);
      }
    }

    .selector-indicator {
      position: absolute;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.dashline {
  width: 100%;
  height: 1px;
  background-position: bottom;
  background-size: 15px 1px;
  background-repeat: repeat-x;
  box-shadow: 0px 1px 9px $lamex-color;
  margin: 15px 0px;
}

.points-input {
  width: 100%;

  .points-input__subtitle {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
    font-family: TerminusBold, sans-serif;
    margin-bottom: 4px;
  }

  .points-input__data {
    width: 100%;
    font-family: Terminus, sans-serif;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $dark-color;
    display: flex;
    justify-content: space-between;
    padding: 3px 5px;
  }
}

.section-container {
  z-index: 1;
  margin: auto;
  border-radius: 44px;
  padding: 6px 5px;
  height: 100%;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 40px 20px;
    border-radius: 44px;
    border: $section-inner-border;
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &.flat {
      border-radius: 0px;
      padding: 0px;
      margin: 0px 0px 6px;
      border: none;
      display: initial;
    }
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    @media (max-width: 684px) {
      display: none;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $text-bg-color;
    border-radius: 8px;
  }

  &.flat {
    position: relative;
    border-radius: 0px;
    padding: 0px;
    height: 680px;
    border-bottom: $section-inner-border;
    overflow: hidden;

    .content-container {
      max-width: 90%;
      max-height: calc(100% - 92px);
      overflow-y: auto;
    }

    @media (max-width: 684px) {
      height: auto;
      .content-container {
        max-width: 100%;
      }
    }
  }
}

.section-header {
  position: absolute;
  z-index: 2;
  background: $dark-purple-color;
  border: 1.75px solid $dark-color;
  box-sizing: border-box;
  border-radius: 42px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-nav {
  width: 100%;
  background: $tab-menu-color;
  display: flex;
  gap: 15px;
  padding: 20px;
  border-top: $section-inner-border;
  overflow-y: auto;

  &.bottom {
    border-top: 1px solid $dark-color;
    background: $main-bg-color;
    position: absolute;
    bottom: 0;
  }
}

p.text {
  width: 90%;
  letter-spacing: -0.04em;
  color: $lamex-color;
  text-shadow: 0px 1px 9px $lamex-color;
  @media (max-width: 684px) {
    width: 100%;
  }
}

.experience-card {
  width: 176px;
  height: 281px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  .message {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 47%;
    margin: auto;
    width: 100px;
    color: white;
    visibility: hidden;
  }

  &:hover {
    // box-shadow: 0px 1px 9px $lamex-color;
    .message {
      visibility: visible;
    }
  }
}

.dashboard-popup {
  visibility: hidden;
  background-color: rgba(17, 20, 51, 0.79);
  width: 100%;
  min-height: 100vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    visibility: visible;
  }
}

.dashboard-content-extra {
  position: absolute;
  top: 84px;
  left: 15px;
  height: calc(100% - 84px);
  z-index: 11;
  display: flex;
  border-radius: 16px;
  transform: translate(1000px, 0px);
  transition: 0.5s;
  width: 100%;
  background: $tab-menu-color;

  &.active {
    transform: translate(0px, 0px);
    transition: 0.5s;
  }
  .button.close {
    width: 48px;
    height: 100%;
    background: $light-purple;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: $lamex-color;
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }

  .content {
    width: 100%;
    padding: 32px;
    background: $tab-menu-color;
    box-sizing: border-box;
    border: 1.75px solid $dark-color;
    overflow-y: auto;
  }

  @media (max-width: 684px) {
    left: 0px;
    flex-direction: column;
    border-radius: 8px;

    .button.close {
      width: 100%;
      height: 48px;

      span {
        transform: rotate(0deg);
      }
    }
    .content {
      padding: 20px;
      border: none;
    }
  }
}

.text__card {
  width: 100%;
  background: $text-bg-color;
  border-radius: 8px;
  padding: 20px;
  margin: 0px 0px;
}

.col-container {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .col {
    width: 50%;

    &.spread {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media (max-width: 684px) {
    flex-direction: column;

    .col {
      width: 100%;
      align-items: center;
    }
  }
}

.check-box {
  width: 100%;
  background: $text-bg-color;
  border-radius: 8px;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: $lamex-color;
    text-shadow: 0px 1px 9px $lamex-color;
  }
  .box {
    width: 36px;
    height: 36px;
    border: 1.75px solid $dark-color;
    box-sizing: border-box;
    border-radius: 8px;
    background: $lamex-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.inactive {
      background: $red-color;
    }
  }
}

.lamex-amount_input_container {
  width: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 40px auto;

  .input_title {
    color: $lamex-color;
    margin-bottom: 15px;
  }
}

.amount_input-component {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    background: $lamex-color;
    border: 1.75px solid $dark-color;
    color: $dark-color;
    box-sizing: border-box;
    border-radius: 42px;
    font-size: 24px;
    line-height: 28px;
    padding: 5px 15px;
    margin-bottom: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .max_button {
    position: absolute;
    right: 10px;
    top: 5px;
    background: $tab-menu-color;
    border: 1.75px solid $dark-color;
    box-sizing: border-box;
    border-radius: 42px;
    padding: 5px 25px;
    cursor: pointer;
  }
}

.molecule {
  width: 25%;
  color: $id-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $text-bg-color;
  border-radius: 8px;
  padding: 30px 20px;
  text-shadow: 0px 1px 9px rgba(243, 92, 192, 0.72);
  transition: 0.1s;

  &:hover {
    box-shadow: 0px 1px 9px rgb(243 92 192 / 72%);
    transform: scale(1.03);
    transition: 0.1s;
  }
  .moleculeImg {
    width: 32px;
    margin: 20px 0px;
  }

  .title {
    font-size: 20px;
  }
  .outcome {
    font-size: 16px;
    color: $green-color;
    margin: 6px 0px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    &.negative {
      color: $red-color;
    }
  }
  .price {
    font-size: 24px;
    color: $lamex-color;
  }
  @media (max-width: 684px) {
    width: 47%;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: $text-bg-color;
  color: $id-color;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 280%;
  left: 15%;
  margin-left: -60px;
  font-size: 18px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $text-bg-color transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.whitelist-container {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;

  .whitelist-card {
    background: $text-bg-color;
    border: 2px solid $dark-color;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    width: 22%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    .whitelist-card__image {
      min-height: 120px;
      @include imageSizing();
    }

    .whitelist-card__title {
      font-weight: 700;
      font-size: 20px;
      margin: 10px 0px;
      color: $lamex-color;
    }
  }

  @media (max-width: 1060px) {
    .whitelist-card {
      width: 30%;
    }
  }

  @media (max-width: 880px) {
    justify-content: space-around;
    .whitelist-card {
      width: 45%;
    }
  }

  @media (max-width: 400px) {
    .whitelist-card {
      width: 70%;
    }
  }
}

.whitelist-details {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__sidebar {
    width: 25%;
    .whitelist-card__image {
      @include imageSizing();
    }
  }
  &__content {
    width: 70%;
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: center;
    &__sidebar {
      width: 50%;
      margin-bottom: 20px;
    }
    &__content {
      width: 100%;
    }
  }

  @media (max-width: 450px) {
    &__sidebar {
      width: 60%;
    }
  }
}

.socials-container {
  &.rating {
    img {
      margin-right: 10px;
    }
  }
  a {
    margin-right: 8px;
    transition: 0.2s;

    &:hover {
      img {
        transform: scale(1.07);
        transition: 0.2s;
      }
    }
  }
  .social-title {
    color: rgb(100, 247, 247);
    text-shadow: rgb(100 247 247) 0px 1px 9px;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
    font-family: TerminusBold, sans-serif;
    margin-bottom: 5px;
  }
}

.custom-select {
  width: 100%;
  height: 36px;
  border: 1.75px solid $dark-color;
  color: $lamex-color;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 0px 15px;
  background: transparent;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%2364F7F7' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}

.inventory-container {
  display: flex;
  gap: 30px;
  color: $id-color;

  .preview-container {
    position: sticky;
    top: 0px;
    width: 35%;
    background: #261736;
    border-radius: 8px;
    padding: 20px;
    height: fit-content;

    .preview-image {
      width: 100%;
      border: 2px solid $dark-color;
      border-radius: 8px;
    }
  }
  .traits-list-container {
    width: 60%;
  }

  @media (max-width: 840px) {
    .preview-container {
      width: 40%;
    }
    .traits-list-container {
      width: 60%;
    }
  }

  @media (max-width: 684px) {
    flex-wrap: wrap;
    justify-content: center;
    .preview-container {
      width: 90%;
    }
    .traits-list-container {
      width: 90%;
    }
  }
}

.trait-preview {
  width: 100%;
  position: relative;
  background: url('../../assets/season-2/inter-dimentionals/IdShadow.jpg')
    no-repeat;
  background-size: contain;
  border-radius: 8px;
  width: fit-content;

  img {
    width: 100%;
    vertical-align: top;
    @include imageSizing();
  }
}

.pack-container {
  border: 1.75px solid $dark-color;
  border-radius: 8px;
  position: relative;
  color: $id-color;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s;
  width: 25%;

  &:hover {
    transform: scale(1.05);
    transition: 0.2s;

    .pack-title {
      transform: scale(1.05);
      transition: 0.2s;
    }

    .packImage {
      transform: scale(1.1);
      transition: 0.2s;
      filter: none;
    }
  }

  .packImage {
    width: 100%;
    border-radius: 8px;
    filter: grayscale(50%);
  }
  .pack-title {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    bottom: 0;
    background: $tab-menu-color;
    position: absolute;
    border-radius: 0px 0px 8px 8px;
    border-top: 1.75px solid $dark-color;
  }
}

.constructor-image {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  border-radius: 8px;
}

.status-item {
  position: absolute;
  padding: 2px 5px;
  top: 0;
  left: 0;
  border: 1.75px solid $dark-color;
  color: $dark-color;
  border-radius: 9px;
}

.overlord-animation {
  position: relative;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;

  img {
    margin: 0 auto;
    width: 50%;
    position: relative;
    z-index: 5;
    vertical-align: bottom;
  }

  .left-eye {
    width: 13%;
    position: absolute;
    left: 33%;
    top: 13%;
    z-index: 4;
  }

  .right-eye {
    transform: scaleX(-1);
    width: 13%;
    position: absolute;
    right: 33%;
    top: 13%;
    z-index: 4;
  }

  .left-hand {
    width: 37%;
    position: absolute;
    left: 5%;
    top: 50%;
    z-index: 6;
  }

  .right-hand {
    transform: scaleX(-1);
    width: 37%;
    position: absolute;
    right: 5%;
    top: 50%;
    z-index: 6;
  }
}

.carousel-item {
  width: 70px;
  height: 70px;
  margin: 0px 10px !important;
  border-radius: 8px;
  cursor: pointer;
}

.carousel-button {
  background: transparent;
  width: 20em;
  margin: 0px 10px;
  height: auto;
  border: none;
  cursor: pointer;

  img {
    width: 30px;
  }

  &:hover {
    filter: drop-shadow(0px 1px 9px #f764c6);
  }
}

.carousel-container {
  position: absolute;
  overflow: hidden;
  left: 0;
  bottom: -200px;
  width: 100%;
  background-color: #261736;
  padding: 20px 0px;
  z-index: 99;
  transition: 0.2s linear;

  &.active {
    bottom: 0px;
    transition: 0.2s linear;
  }
}

.dashboard-pipe {
  overflow: hidden;
  padding: 0px 5px;
  // margin: 0 auto;
  height: 100%;
  width: 35px;
  border: 1.75px solid $dark-color;
  background-color: #ee22aa;
  box-shadow: 0 0 40px #ee22aa;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  &-inner {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    background-color: $id-color;
    position: relative;
    z-index: 2;

    // .inner {
    //   position: absolute;
    //   width: 100%;
    //   height: 50%;
    //   overflow: hidden;
    //   border-radius: 44px;
    // }

    // &:after {
    //   content: '';
    //   position: absolute;
    //   height: 100%;
    //   width: 100%;
    //   left: 0;
    //   top: 0;
    //   border-radius: 44px;
    //   border: 8px solid $id-color;
    // }
  }
}

.title-bar {
  position: absolute;
  @include center-absolute;
  background: $id-color;
  border: 1.75px solid $dark-color;
  border-radius: 14px;
  height: auto;
  width: 300px;
  top: 30px;
  z-index: 2;
  font-size: 20px;
  line-height: 28px;
  text-align: center;

  .wrapper {
    padding: 5px 15px;
    border-radius: 14px;
    border: 6px solid rgba(255, 255, 255, 0.24);
  }
}

.card-container {
  width: 300px;
  z-index: 2;

  .preview-container {
    position: sticky;
    top: 0px;
    width: 100%;
    background: #261736;
    border-radius: 8px;
    padding: 20px;
    height: fit-content;

    .preview-image {
      width: 100%;
      border: 2px solid $dark-color;
      border-radius: 8px;
    }
  }
}

.back-button {
  position: absolute;
  display: flex;
  width: 36px;
  height: 36px;
  background: $tab-menu-color;
  border: 1.75px solid $dark-color;
  color: $id-color;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
}

.evolution-animation-container {
  width: 300px;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;

  .trait-preview {
    z-index: 3;
    position: relative;
  }

  .filling {
    height: 0%;
    width: 100%;
    z-index: 5;
    background-color: $id-color;
    position: absolute;
    display: flex;
    border-radius: 8px;
    bottom: 0px;
    overflow: hidden;
  }
}


.holder-wrapper {
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  

  .holder {
    max-width: 700px;
    padding: 10px;
    text-align: center;
    display: flex;  
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 30px;
      line-height: 40px;
    }
    p {
      margin: 10px 0px;
      font-size: 20px;
      line-height: 24px;
    }
    .a {
      margin-top: 30px;
      width: 50%!important;
    }
  }
}