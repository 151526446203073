@keyframes cloud1 {
  from {
    transform: translateX(-3rem)
  }

  50% {
    transform: translateX(5rem);
  }

  to {
    transform: translateX(-3rem)
  }

}

@keyframes cloud2 {
  from {
    transform: translateX(-3rem)
  }

  50% {
    transform: translateX(8rem)

  }

  to {
    transform: translateX(-3rem)
  }

}

@keyframes cloud3 {
  from {
    transform: translateX(3rem)
  }

  50% {
    transform: translateX(-5rem);

  }

  to {
    transform: translateX(3rem)
  }
}


@keyframes cloud1-mobile {
  from {
    transform: translateX(-2rem)
  }

  50% {
    transform: translateX(10rem)
  }

  to {
    transform: translateX(-2rem)
  }

}

@keyframes cloud2-mobile {
  from {
    transform: translateX(15rem)
  }

  50% {
    transform: translateX(10rem)
  }

  to {
    transform: translateX(15rem)
  }

}

@keyframes cloud3-mobile {
  from {
    transform: translateX(3rem)
  }

  50% {
    transform: translateX(12rem)
  }

  to {
    transform: translateX(3rem)
  }

}

@keyframes nlo {
  from {
    transform: translateX(-3rem)
  }

  50% {
    transform: translateX(3rem);

  }

  to {
    transform: translateX(-3rem)
  }
}

@keyframes nlo-mobile {
  from {
    transform: translateX(-3rem)
  }

  50% {
    transform: translateX(3rem);
  }

  to {
    transform: translateX(-3rem)
  }
}

@keyframes gallary {
  from {
    transform:translateX(0rem)
  }
  to {
    transform:translateX(-40rem)
  }
}


@keyframes cursor {
  from {
    opacity: 1;
  }

  50% {
    opacity: .3;
  }

  to {
    opacity: 1;
  }
}


// Bubbles

@keyframes bubbleStatistic {

  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-3em);
  }

}

@keyframes bubbleFooter {

  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-35em);
  }
}

@keyframes bubblePlan {

  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-25em);
  }
}

@keyframes bubbleDiscord {

  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-15em);
  }
}

@keyframes bubblePlan {

  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-20em);
  }
}

@keyframes bubbleProgressBar {

  from {
    transform: translateX(-20em);
    transform: translateY(0.5em);
  }

  to {
    transform: translateX(100em);
  }
}

@keyframes bubblePlanProgress {

  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-200em);
  }
}

@keyframes bubblePlanProgressPanel {

  from {
    transform: translateY(0%); 
  }

  to {
    transform: translateY(-5em);
  }
}


@keyframes blowTV {

  from {

    box-shadow:  0 0 0 13px #101820, 0 0 143px rgba(255, 255, 255, 1);
  }

  50% {
    box-shadow: 0 0 0 13px #101820, 0 0 143px rgba(255, 255, 255, 0.3);
  }

  to {

    box-shadow: 0 0 0 13px #101820, 0 0 143px rgba(255, 255, 255, 1);
  }

}

@keyframes leftGate {
  from {
    transform: translateX(0rem);
  }
  to {
    transform: translateX(-50rem);
  }
}


@keyframes rightGate {
  from {
    transform: translateX(0rem);
  }

  to {
    transform: translateX(50rem);
  }
}

@keyframes blowButtonCentralVessel {

  from {
    box-shadow: 0 0 40px #ff4040;
  }

  50% {
    box-shadow: 0 0 15px rgba(255, 64, 64, 0.5);
  }

  to {

    box-shadow: 0 0 40px #ff4040;
  }
}


// settelite

@keyframes settelite {
  from {
    transform: translate(-1rem,  -1rem); 
  }

  50% {
    transform: translate(1rem,  1rem); 
  }
  to {
    transform: translate(-1rem,  -1rem); 
  }

}


// light

@keyframes light {
  from {
    transform: rotate(0deg); 
  }

  to {
    transform: rotate(360deg); 
  }

}

// moneys

@keyframes money {
  from {
    transform: translateY(0rem);
  }

  to {
    transform: translateY(100rem);
  }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes floating {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(4deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
    box-shadow: 2px 2px 20px 10px rgba(141, 255, 31, 0.3);
	}

	70% {
		transform: scale(1);
    box-shadow: 2px 2px 20px 20px rgba(141, 255, 31, 0.2);
	}

	100% {
		transform: scale(0.95);
    box-shadow: 2px 2px 20px 10px rgba(141, 255, 31, 0.2);
	}
}

@keyframes pulse-shard {
	0% {
		transform: scale(0.95);
    box-shadow: 2px 2px 20px 10px rgba(213, 124, 224, 0.4);
	}

	70% {
		transform: scale(1);
    box-shadow: 2px 2px 20px 20px rgba(213, 124, 224, 0.3);
	}

	100% {
		transform: scale(0.95);
    box-shadow: 2px 2px 20px 10px rgba(213, 124, 224, 0.4);
	}
}

@keyframes buttonPulse {
	0% {
		transform: scale(0.95);
    box-shadow: 2px 2px 10px 0px rgba(141, 255, 31, 0.3);
	}

	70% {
		transform: scale(1);
    box-shadow: 2px 2px 10px 10px rgba(141, 255, 31, 0.2);
	}

	100% {
		transform: scale(0.95);
    box-shadow: 2px 2px 10px 0px rgba(141, 255, 31, 0.2);
	}
}

@keyframes creepzPulse {
	0% {
    box-shadow: 2px 2px 10px 0px rgba(255, 255, 255, 0.05);
    background: rgba(255, 255, 255, 0.05);
	}

	70% {
    box-shadow: 2px 2px 10px 10px rgba(255, 255, 255, 0.02);
    background: rgba(255, 255, 255, 0.02);
	}

	100% {
    box-shadow: 2px 2px 10px 0px rgba(255, 255, 255, 0.05);
    background: rgba(255, 255, 255, 0.05);
	}
}


@keyframes bubbleEvolutionPool {
  from {
    transform: translateY(252px);
  }

  to {
    transform: translateY(0px);
  }
}