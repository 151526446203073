@keyframes noise {
  0% {
    transform: translateX(0px,0px); }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px,100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}



@keyframes rotate {
  from {
    transform: rotate(0deg); 
  }

  to {
    transform: rotate(360deg); 
  }
}

.pulse {
  position: absolute;
  top: 40%;
  left: 29%;
  transform: translate(-505, -50%);
  width: 43%;
  height: 25%;
  background: #F764C6;
  border: 2px solid #F764C6;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 0 36px #5C36E1,
          0 0 0 40px #F764C6;
}

.pulse:before,
.pulse:after {
  content: '';
  position: absolute;
  left: -30px;
  top: -30px;
  right: -30px;
  bottom: -30px;
  border: 2px solid #c300ff;
  box-shadow: inset 0 0 30px #ffffff;
  border-radius: 50%;
  animation: animate 1.5s linear infinite
}

.pulse:after {
  animation-delay: 0.75s;
}

.flip-and-hide {
  animation: flipAndHide 0.5s linear;
  animation-fill-mode: forwards;
}

.flip-and-show {
  animation: flipAndShow 0.5s linear;
  animation-fill-mode: forwards;
}

.shake-hover {
  cursor: pointer;
  &:hover {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both infinite;
    transform: translate3d(0, 0, 0);
  }
}

.shake-still {
  cursor: pointer;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both infinite;
  transform: translate3d(0, 0, 0);
}

.fadeIn {
  animation: showAndFadeIn 0.5s linear;
  animation-fill-mode: forwards;
  transform: translateY(-100px);
  display: none;
}

.redPulse {
  animation: redPulse 1s linear infinite;
}

@keyframes redPulse {
  0% {
    box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.5);
  }

  100% {
    box-shadow: 0px 0px 20px rgba(255, 0, 0, 0.9);
  }
}

@keyframes flipAndHide {
  0% {
    transform: rotateY(0deg);
  }

  100% {
      transform: rotateY(90deg);
      display: none;
  }
}

@keyframes flipAndShow {
  0% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

@keyframes showAndFadeIn {
  0% {
    display: block;
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes animate {
  0% {
    transform: scale(0.7);
  }

  100% {
      transform: scale(2.0);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes pipeBubbles {

  from {
    transform: translateY(150em);
  }

  to {
    transform: translateY(0em);
  }
}


@keyframes fillPool {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

@keyframes drainPool {
  from {
    height: 100%;
  }

  to {
    height: 0%;
  }
}