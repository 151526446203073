@font-face {
  font-family: "Terminus";
  src: url("./fonts/Terminus.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "TerminusBold";
  src: url("./fonts/Terminus-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "AncientHand";
  src: url("./fonts/AncientHand.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "TerminusItalic";
  src: url("./fonts/Terminus-Italic.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Amatic SC";
  src: url("./fonts/AmaticSC-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Long+Cang&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gaegu:wght@300;400;700&display=swap');

// font-family: Terminus sans-serif;
// font-family: TerminusBold sans-serif;
// font-family: TerminusItalic sans-serif;
// font-family: 'Dancing Script', cursive;
// font-family: 'Amatic SC', cursive;
// font-family: 'Long Cang', cursive;
// font-family: 'Shadows Into Light Two', cursive;
// font-family: 'Gaegu', cursive;