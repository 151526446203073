@import "variables.scss";
@import "mixins.scss";
@import "animation.scss";
@import "fonts.scss";

// Media width 1920

@media (min-width: 1920px) {
  .container {
    width: 1920px;
    margin: 0 auto;
    height: 100%;
  }

  @keyframes nlo {
    from {
      transform: translateX(2rem);
    }

    50% {
      transform: translateX(6rem);
    }

    to {
      transform: translateX(2rem);
    }
  }

  .statistic__additional-panel {
    margin-right: 15px;
    padding: 0 40px 0 40px;
    height: 55px;
    font-size: 18px;
  }

  .creators__team {
    margin-top: 100px;
  }

  .header__nav-item {
    font-size: 22px;
    margin-right: 50px;
  }

  .header-scroll_nav {
    margin-right: 50px;
    font-size: 22px;
  }

  .header__main-callout {
    bottom: 70%;
    left: 60%;
  }

  .city__info-title {
    width: 400px;
    min-height: 150px;
    font-size: 70px;
  }

  .city__info-text {
    margin: 0px auto 0;
    line-height: 110%;
    width: 800px;
    height: auto;
    min-height: 145px;
    font-size: 20px;
  }

  .interlayer__blocks {
    height: 80px;
    z-index: 20;
    margin-top: -15px;
    margin-bottom: -15px;
    position: relative;
  }

  .discord__button {
    margin: 70px auto 90px;
    height: 65px;
    width: 280px;

    span {
      font-size: 24px;
    }
  }

  .discord {
    max-height: 1400px;
  }

  .creators__video {
    margin-top: 90px;
    position: relative;
  }

  .creators__team-cards {
    width: 40%;
  }

  .creators__team-card .card__image {
    margin: 0 auto;
  }

  .creators {
    min-height: 2650px;
  }

  .answers__container {
    width: 70%;
    margin: 0 auto;
    height: auto;
  }

  .footer__info-author,
  .footer__info-contract {
    font-size: 21px;
  }
}

@media (min-width: 1600px) {
  .creators__team-cards {
    width: 70%;
  }
}

// Media width 1600

@media (min-width: 1600px) {
  // .header__main-globe {
  //   width: 60%;
  // }

  .interlayer__blocks {
    height: 65px;
  }

  // .header__main-creep {
  //   width: 770px;
  //   bottom: 10%
  // }

  .header__main-callout {
    max-width: 90%;
    bottom: 70%;
    left: 60%;
  }

  .statistic__pipe {
    top: -10%;
  }

  .statistic__pipe-lower {
    height: 370px;
    width: 50%;
  }

  .statistic .container {
    position: static;
  }

  .plan .container {
    position: static;
  }

  .creators .container {
    position: static;
  }

  // .header__main-lizard {
  //   .right-hand {
  //     right: -15%;
  //     top: 23%;
  //   }
  // .left-hand {
  //     left: -15%;
  //     top: 23%;
  //   }
  //   img {
  //     bottom: 10%;
  //   }
  //   .right-eye, .left-eye {
  //     top: -4%;
  //   }
  // }
}

// Media width 1440

@media (max-width: 1440px) {
  .statistic__pipe {
    top: -2rem;
  }
}

@media (max-width: 1390px) {
  .creators__team-cards {
    margin: 110px auto 0;
    width: 80%;
  }
}

// Media width 1300

@media (max-width: 1300px) {
  .answers .container {
    position: static;
  }

  .creators__team .bg__pipe-2 {
    top: -35rem;
    left: 8%;
  }

  .statistic__pipe {
    top: 3rem;
  }

  .answers__item.active .answer {
    min-height: 150px;
  }

  .creators {
    min-height: 2300px;
  }

  .creators__team-card .card__image {
    width: 310px;
    height: 310px;
    padding: 27px;
  }

  .discord {
    max-height: 1050px;
  }

  .city__info {
    // margin: 170px auto 0;
    width: 70%;
    top: 15%;
  }

  .city__info-title {
    width: 50%;
    height: auto;
  }

  .city__info-text {
    margin: 20px auto 0;
    width: 55%;
  }

  .city__bg .creeps__trump {
    right: 10%;
    bottom: -40px;
  }

  .city__bg .creeps__black {
    left: 10%;
  }

  .statistic__panel-subtitle {
    font-size: 17px;
    margin: 5px auto 0;
    width: 80%;
  }

  .statistic__additional-panel {
    max-width: 220px;
    margin-right: 15px;
    padding: 0 15px;
    height: 55px;
    background-color: #0b302b;
    font-size: 16px;
    display: flex;
    transition: box-shadow 0.1s linear;

    &:first-child {
      margin-left: 15px;
    }

    &:last-child {
      margin-right: 15px;
    }
  }

  .discord .discord__content .discord__pipe .pipe {
    width: 40px;
    height: 80%;
  }

  .discord .discord__content {
    width: 90%;
    margin: 70px auto 0;
  }

  .discord__titles {
    width: 70%;
    margin: 50px auto;
  }

  .creators__team-card .card__image {
    width: 250px;
    height: 250px;
    padding: 27px;
  }

  .creators {
    min-height: 2000px;
  }

  .answers__container {
    width: 60%;
  }

  .answers__item {
    width: 100%;
  }

  .footer__panel {
    margin: 120px auto;
    width: 900px;
    height: 500px;
  }

  .header__nav {
    top: 10px;
  }

  .header__nav-item {
    font-size: 16px;
    margin-right: 20px;
    display: flex;

    span {
      margin: 0 auto;
    }
  }

  .header {
    margin: 16px 20px 0 20px;
  }

  .header-scroll__button {
    width: 205px;
    height: 45px;
    margin: 15px 15px 0 0;
  }

  .creators__team-cards {
    margin: 110px auto 0;
    width: 65%;
  }
}

@media (max-width: 1110px) {
  .header__main-callout {
    bottom: 65%;
    left: 65%;
    width: 30%;
  }

  .creators__team-cards {
    margin: 110px auto 0;
    width: 75%;
  }

  .header__main-globe {
    width: 900px;
  }
}

@media (max-width: 990px) {
  .statistic__panel {
    margin-right: 25px;
    height: 200px;
  }

  .statistic__pipe {
    top: -10rem;
  }

  .creators__team .bg__pipe-2 {
    top: -20rem;
    left: 10.5%;
  }

  .discord .discord__content .discord__panels .discord__panel {
    min-height: 250px;
    max-height: 250px;
  }

  .creators__team-card .card__image {
    width: 230px;
    height: 230px;
    padding: 20px;
  }

  .footer {
    height: 835px;

    background: url("../assets/footer/bg-mobile.svg") no-repeat center center /
      cover;

    &__panel {
      width: 642px;
      height: 385px;
      border-radius: 56px 56px 48px 48px;
      border: 1.1px solid #101820;
      padding: 4px 2px;

      &-pipes {
        .pipe {
          width: 105px;

          &-1 {
            left: -105px;
            top: 16%;
          }

          &-2 {
            left: -105px;
            bottom: 16%;
          }

          &-3 {
            right: -105px;
            top: 16%;
          }

          &-4 {
            right: -105px;
            bottom: 16%;
          }
        }
      }

      &-text {
        span {
          width: 100%;
          font-size: 54px;
        }
      }

      &-button {
        bottom: -15px;
        transform: translateX(-50%) scale(1.02);
      }
    }

    &__info {
      // position: absolute;
      // bottom: 54px;
      // height: 200px;
      // width: 100%;
      // padding-bottom: 25px;
      // background-image: url('../assets/footer/bg-info-mobile.svg');
      // background-size: cover;
      // flex-flow: column nowrap;
      // justify-content: space-around;

      &-author {
        margin: 0 auto;
        order: 3;
      }

      &-contract {
        margin: 0 auto;
        order: 2;
        font-size: 15px;
      }
    }
  }

  .pyramid .figure-1 {
    width: 200px;

    img {
      width: 30px;
      max-width: 50px;
    }

    .pyramid__figure-button {
      width: 20px;
      max-width: 20px;
    }
  }

  .pyramid .figure-2 {
    height: 0;
    width: 340px;
  }

  .pyramid .figure-3 {
    width: 460px;
  }

  .pyramid .figure-4 {
    width: 580px;
  }

  .pyramid .figure-5 {
    width: 700px;
  }

  .pyramid__figure-name {
    font-size: 26px;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
  }

  .pyramid__figures {
    min-width: 100%;
    height: auto;
    margin: 200px auto 0;
  }
}

// Media width 900

@media (max-width: 930px) {
  .header-scroll__button {
    width: 180px;
    height: 40px;
  }

  .header-scroll__navs {
    transform: translate(-55%, -50%);
  }

  .header-scroll_nav {
    font-size: 17px;
  }

  .creators__team-cards {
    width: 80%;
  }
}

// Media width 850

@media (max-width: 768px) {
  .mobile-buttons {
    display: flex;
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    justify-content: center;
    bottom: 50px;

    .header__buttons {
      display: flex;

      .header__button {
        display: flex;
        z-index: 99;
      }
    }
  }

  .header__main-button {
    // display: none;
  }

  .popup.project__info .staking-stat-wrapper {
    flex-direction: column;
    width: 100%;

    .card {
      width: 100%;
    }
  }

  .popup.project__info .staked-creeps-wrapper {
    justify-content: space-around;
    gap: 15px;
  }

  .popup.project__info .staking_bottons-wrapper {
    gap: 10px;
  }

  .popup.project__info .tabs-menu-wrapper .creep-item,
  .popup.project__info .creepz-layout-wrapper .creep-item {
    width: 45%;
  }

  .pyramid__title {
    font-size: 66px;
    width: 70%;
    margin: 55px auto 0;
  }

  .pyramid .figure-1 .pyramid__figure-name {
    margin-top: 70px;
    font-size: 24px;
  }

  @keyframes bubbleDiscord {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(30em);
    }
  }

  .discord {
    .bubble {
      bottom: 0;

      &.small {
        width: 3.7px;
        height: 4px;
      }

      &.medium {
        width: 10px;
        height: 9px;
      }

      &.big {
        width: 15px;
        height: 14px;
      }

      &-0 {
        top: 5px;
      }

      &-1 {
        bottom: 6px;
      }

      &-2 {
        top: 3px;
      }

      &-3 {
        top: 6px;
      }

      &-4 {
        bottom: 5px;
      }

      &-5 {
        bottom: 5px;
      }

      &-6 {
        bottom: 3px;
      }

      &-7 {
        top: 6px;
      }

      &-8 {
        top: 8px;
      }

      &-9 {
        top: 8px;
      }

      &-10 {
        top: 13px;
      }

      &-11 {
        bottom: 6.7px;
      }

      &-12 {
        bottom: 13px;
      }

      &-13 {
        bottom: 5.29px;
      }

      &-14 {
        top: 12px;
      }
    }
  }

  .discord {
    .pipe {
      .inner {
        .discord__logo {
          position: absolute;

          &.logo-1 {
            left: 0;
            animation: bubbleDiscord linear 8s infinite;
          }

          &.logo-2 {
            left: 0;
            animation: bubbleDiscord linear 10s infinite;
          }
        }
      }
    }
  }

  .armoury {
    padding-bottom: 40px;
    max-height: none !important;

    .armory_titles {
      width: 90%;
    }

    .armory_title {
      font-size: 53px;
      margin-bottom: 50px;
    }

    .armoury__content {
      &-inner {
        .inner-panel {
          .light {
            &_right {
              right: 10%;
            }

            &_left {
              left: 10%;
            }
          }
        }
      }
    }

    .armoury__panels {
      // width: 100%;
      flex-direction: column;
      align-items: center;
      .inner {
        min-height: 250px !important;
      }

      .armoury_panel {
        margin-bottom: 12px;
      }
    }
  }

  .answers {
    .bg__pipe-3 {
      height: 80px;
      right: -40px;
    }
  }

  .header-scroll {
    display: none;
  }

  .intro.active-menu {
    height: 100vh;
  }

  .header {
    margin: 0;

    &__button {
      display: none;
    }
  }

  .header__nav {
    display: none;
  }

  .header__main {
    &-globe {
      width: 100%;
      height: 55%;
      object-fit: contain;
      transform: translateX(-50%) scale(1.6, 1.8);
    }

    &-creep {
      bottom: 31%;
      height: auto;
      width: 100%;
      transform: translate(-48%, -50%) scale(1.45, 1.5);
    }

    &-map {
      margin-top: -270px;
    }

    &-lizard {
      width: 500px;
      margin-bottom: -50px;
      img {
        width: 380px;
      }

      .left-eye {
        width: 100px;
        left: 23%;
        top: 17%;
      }

      .right-eye {
        width: 100px;
        right: 23%;
        top: 17%;
      }

      .left-hand {
        width: 250px;
        left: -10%;
        top: 40%;
      }

      .right-hand {
        width: 250px;
        right: -10%;
        top: 40%;
      }
    }

    &-callout {
      display: none;

      &-mobile {
        display: block;
        position: absolute;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        bottom: 33%;
        left: 50%;
        transform: translate(-50%);
        z-index: 25;
      }
    }

    &-progress {
      width: 90%;
      min-width: 50%;
      height: 48px;
      border: $default-border-mobile;

      .progress {
        height: 35px;

        &__inner {
          box-shadow: 0 0 40px rgba(255, 255, 255, 0.2);
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 6px solid rgba(255, 255, 255, 0.25);
          border-radius: 44px;
          z-index: 1;
        }
      }
    }
  }

  .header__menu-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow-y: auto;
    background: #0b302b;
    z-index: 100;

    &.active {
      display: block;
    }

    .logo {
      display: block;
      height: 150px;
      width: 150px;
      margin: 80px auto 0;
    }

    .header__menu-navs {
      display: flex;
      // flex-flow: column nowrap;
      width: 240px;
      margin: 40px auto 0;
      overflow: visible;
    }

    .header__menu-nav {
      cursor: pointer;

      span {
        margin: auto;
      }
    }

    .links {
      display: flex;
      margin: 0px auto;
      flex-flow: row nowrap;
      justify-content: center;

      div {
        margin: 0px 5px;
      }

      img {
        margin-right: 20px;
        filter: drop-shadow(0 0 40px rgba(115, 255, 66, 0.36));

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .pipe {
      background: url("../assets/intro/mobile-nav.svg");
      position: fixed;
      left: 0;
      width: 100%;
      height: 40px;
      z-index: 0;
    }

    .header__menu-nav {
      width: 100%;
      height: 38px;
      text-align: center;
      background-color: #0f2320;
      border: 1.75px solid #101820;
      border-radius: 42px;
      margin-bottom: 24px;
      box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);
      display: flex;
      color: #8dff1f;
      align-items: center;
      justify-content: center;

      &.router {
        background-color: rgb(100, 247, 247);
        border: 1.75px solid rgb(16, 24, 32);
        color: rgb(38, 23, 54);
        text-transform: uppercase;
      }

      span {
        margin: auto;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: #8dff1f;
        text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .header__logo {
    z-index: 15;
    max-width: 50%;
    margin-left: 15px;
    margin-top: 0px;
    transform: rotate(15deg);
  }

  .header__nav-mobile {
    display: block;
    position: absolute;
    width: 100%;
    height: 40px;
    background: url("../assets/intro/mobile-nav.svg") no-repeat center center /
      cover;
    left: 0;
    top: 5px;

    &-button {
      position: absolute;
      display: flex;
      top: -5px;
      right: 3px;
      width: 48px;
      height: 48px;
      border-radius: 10px;
      border: $default-border;
      background-color: #0b302b;

      &.close {
        background-color: #fa4b28;
        box-shadow: 0 0 80px rgba(255, 64, 64, 0.9);
        border: 1.75px solid #101820;

        .inner {
          background-color: rgba(242, 244, 248, 0.51);
          position: relative;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(135deg);
            width: 20px;
            height: 4px;
            background-color: #0f2320;
          }

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 4px;
            height: 20px;
            background-color: #0f2320;
          }

          div {
            display: none;
          }
        }
      }

      .inner {
        margin: auto;
        width: 36px;
        height: 34px;
        background-color: #425855;
        border-radius: 4px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 7px 6px;

        div {
          margin-bottom: 4px;
          height: 4px;
          width: 24px;
          background-color: #0f2320;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .city {
    background-color: $color-bg-city-mobile;

    .city__bg {
      margin-top: 300px;
    }

    &__info {
      top: 20%;
      width: 90%;

      &-title {
        font-size: 55px;
        width: 95%;
        margin: -100px auto 0px;
      }

      &-text {
        margin: 30px auto 0;
        width: 81%;
        font-size: 16px;
      }
    }

    .cloud-1 {
      left: -2%;
      top: 4%;
      width: 100px;
      animation: cloud1-mobile 20s infinite linear 0.4s;
    }

    .cloud-2 {
      right: 50%;
      top: 12%;
      width: 140px;
      animation: cloud2-mobile 20s infinite linear 0.7s;
    }

    .cloud-3 {
      left: -50%;
      top: 50%;
      width: 220px;
      animation: cloud3-mobile 20s infinite ease 1s;
    }

    &__bg {
      //display: none;

      .houses {
        &-left,
        &-right {
          width: 100%;
          height: 100%;
          position: absolute;

          img {
            width: 70%;
          }
        }

        &-left {
          &__lvl-1 {
            left: -53px;
            z-index: 20;
          }

          &__lvl-2 {
            bottom: 140px;
            left: -100px;
          }
        }

        &-right {
          right: -28px;

          &__lvl-1 {
            bottom: 0;
            right: -100px;
          }

          &__lvl-2 {
            bottom: 95px;
            right: -150px;
          }

          &__lvl-3 {
            display: none;
          }
        }
      }

      .further-bg {
        &__left {
          display: none;
        }

        &__right {
          right: 10%;
          bottom: 0;
        }
      }

      .creeps {
        img {
          width: 90%;
        }

        &__black {
          left: -30%;
          bottom: -7%;
          z-index: 100;
        }

        &__trump {
          right: -20%;
          bottom: -30px;
          z-index: 100;
        }
      }

      .grey-block {
        display: none;
      }

      .nlo {
        width: 140px;
        height: 100%;
        top: 50%;
        animation: nlo 15s infinite ease 0.2s;
        left: -2%;
        z-index: 14;

        &__model {
          width: 80%;
          height: 65px;
          z-index: 30;
        }

        &__vector {
          margin-top: -50px;
          z-index: 14;
          width: 100%;
          height: 300px;
        }
      }
    }
  }

  .statistic {
    padding: 70px 0;

    &__pipe {
      position: absolute;
      left: 50%;
      top: -25px;
      z-index: 1;
      min-width: 100%;
    }

    &__pipe-lower {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      height: 370px;
      width: 50%;
    }

    &__gallery {
      margin: 0;

      &-cards {
        min-width: 90px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: center;
        margin-right: 25px;
        overflow: hidden;
      }

      &-card {
        border-radius: 7%;
        width: 214px;
        min-width: 214px;
        height: 214px;
        margin-right: 20px;

        .card__photo {
          margin: auto;
          width: 180px;
          height: 180px;
        }

        &:before {
          bottom: 7px;
          right: 13px;
          height: 3px;
          width: 3px;
        }

        .gallery-border-effect {
          .upper-line {
            left: 12px;
            top: 3px;
            width: 175px;
            height: 9px;
            border-radius: 43px;

            .upper-inner-line {
              left: 37px;
              border-radius: 43px;
              top: 3px;
              width: 126px;
              height: 3px;
            }
          }

          .lower-line {
            left: 12px;
            bottom: 3px;
            width: 175px;
            height: 9px;
            border-radius: 43px;

            .lower-inner-line {
              left: 12px;
              border-radius: 43px;
              top: 3px;
              width: 126px;
              height: 4px;
            }
          }
        }

        &.gif {
          min-width: 90px;
          width: 90px;
          height: 90px;

          .card__photo {
            margin: auto;
            width: 74px;
            height: 74px;
            border-radius: 9px;
          }

          &:before {
            bottom: 4px;
            right: 7px;
            height: 1px;
            width: 1px;
          }

          &:after {
            bottom: 2px;
            left: 7px;
            height: 3px;
            width: 67px;
            background-color: rgba(25, 27, 30, 0.11);
            border-radius: 18px;
          }

          &:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &__info {
      margin-top: 38px;

      &-title {
        font-size: 53px;
        margin: 0 auto;
        width: 90%;
        letter-spacing: 3px;
        text-align: center;
        text-transform: uppercase;
        @include shadowText(1.75px, #101820);
      }

      &-panels {
        display: flex;
        width: 95%;
        height: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        flex-flow: column nowrap;
        overflow: visible;

        .statistic__panel {
          width: 100%;
          box-shadow: 0 0 40px rgba(255, 255, 255, 0.4);
          margin: 0 0 25px 0;
          border: $default-border-mobile;
          max-height: 170px;

          &:first-child {
            .statistic__panel-subtitle {
              width: 30%;
            }
          }

          &:last-child {
            margin: 0;
          }

          .wrapper {
            border-radius: 44px;
            border: 8px solid rgba(255, 255, 255, 0.24);
          }

          &-title {
            font-size: 55px;
            width: 50%;
            margin: 10px auto 0;
          }

          &-subtitle {
            font-size: 17px;
            margin: 10px auto;
            width: 55%;
          }
        }
      }

      &-subtitle {
        font-size: 34px;
        margin: 50px auto 0;
        text-transform: uppercase;
      }

      &-additional-panels {
        width: 90%;
        flex-flow: column nowrap;
        margin: 20px auto 60px;
        height: auto;
        overflow: visible;

        &:after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          z-index: 1;
          width: 130%;
          height: 1em;
          background: url("../assets/statistic/vector.png") no-repeat center
            center / cover;
        }
      }
    }

    &__additional-panel {
      margin: 0 0 8px 0;
      max-width: 100%;
      width: 100%;
      padding: 0;
      border: 1.75px solid #101820;
      height: 40px;
      background-color: #0f2320;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .answers {
    &__container {
      width: 93%;
      margin: 0 auto;
      height: auto;
    }

    &__title {
      margin-top: 60px;
      font-size: 53px;
      margin-left: 24px;
      @include shadowText(1.1px, #101820);
    }

    &__items {
      margin-top: 25px;
      display: flex;
      flex-flow: column nowrap;
    }

    &__item {
      width: 100%;
      margin-bottom: 29px;

      &.active {
        .answer {
          min-height: 160px;
          padding: 30px 24px 16px 16px;
        }
      }

      &-lumps {
        min-width: 15px;
      }

      .answer {
        font-size: 15px;
        font-family: Terminus, sans-serif;
        line-height: 18px;
        width: 100%;
        background-color: #0b302b;
        top: -10px;
        transform: translateY(-50%);

        span {
          display: none;
          transition: display 0.1s linear;
        }

        &:before {
          left: -1px;
          height: 21px;
          width: calc(100% + 2px);
        }
      }

      .question {
        min-height: 104px;
        padding: 14px 0 14px 2px;
        border-radius: 14px;

        &__button {
          min-width: 40px;
          min-height: 40px;
          right: -10px;

          &-inner:after {
            width: 55%;
            height: 1.75px;
          }

          &-inner:before {
            width: 1.75px;
            height: 55%;
          }
        }

        &__text {
          margin-right: 16px;
          min-height: 72px;
          height: auto;
          max-height: 100%;
          display: flex;
          font-family: TerminusBold, sans-serif;
          font-size: 18px;
          vertical-align: center;
          border-radius: 7px;
          background-color: rgba(255, 255, 255, 0.11);

          span {
            margin: auto 40px auto 20px;
          }
        }
      }
    }
  }

  .discord {
    max-height: 1550px;

    &__titles {
      width: 95%;
      margin: 50px auto;
    }

    &__title {
      font-size: 54px;
      @include shadowText(1.1px, $color-text-black-1);

      span {
        font-size: 34px;
      }
    }

    &__subtitle {
      text-align: center;
      font-size: 24px;
      margin-top: 16px;
    }

    .discord__content {
      flex-flow: column nowrap;
      width: 95%;
      margin: 35px auto 0;

      .discord__pipe {
        width: 100%;
        height: auto;
        flex-flow: row nowrap;

        .circle {
          margin: 0 20px;
        }

        .pipe {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 75%;
          height: 43px;
          border: $default-border-mobile;
          padding: 5px 7px;

          .inner {
            &:before {
              border: 2px solid rgba(255, 255, 255, 0.24);
            }
          }
        }
      }

      .discord__panels {
        width: 96%;
        margin: 32px auto;
        flex-flow: column wrap;
        justify-content: initial;

        .discord__panel {
          width: 100%;
          height: 170px;
          background-color: #0f2320;
          padding: 3px 4px;
          border: $default-border-mobile;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          .inner {
            height: 100%;
            width: 100%;
            border-radius: 44px;

            span {
              line-height: 110%;
              font-family: Terminus, Arial, sans-serif;
            }
          }
        }
      }
    }
  }

  .creators {
    min-height: 2500px;
    background: url("../assets/creators/bg-mobile.jpeg") no-repeat center center /
      cover;

    &__video {
      margin-top: 60px;

      .wrapper {
        width: 95%;
      }

      &-title {
        width: 70%;
        font-size: 54px;
      }

      &-player {
        width: 100%;
        margin: 32px auto 0;
        max-height: 400px;
        border-radius: 22px;
        border: 0.79px solid #101820;
        padding: 8px;

        &:after {
          width: 90px;
          height: 90px;
        }

        .video__player-pipe {
          display: none;
        }

        img.video__background {
          border-radius: 22px;
          border: 0.79px solid #000;
        }
      }

      &-lamps {
        width: 15px;
        bottom: 2px;

        .lamp.blue {
          background-color: #00c2ff;
        }

        .lamp.red {
          background-color: #ff0000;
        }

        .lamp {
          border: 0.79px solid #101820;
          width: 6px;
          height: 6px;
        }
      }
    }

    &__team {
      margin-top: 0;
      width: 100%;
      padding-top: 50px;

      &-title {
        font-size: 54px;
      }

      &-cards {
        margin: 32px auto 0;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &-card {
        width: 296px;
        margin: 0 auto 35px;

        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          margin: 0 auto 35px;
        }

        &:last-child {
          margin: 0 auto 50px;
        }

        .card__image {
          width: 296px;
          height: 296px;
          padding: 24px;

          &-status {
            top: 30px;
          }
        }

        .card__info {
          max-width: 192px;
          max-height: 55px;
          margin: -35px auto 0;
          padding: 3px 0 10px 0;

          &-name {
            font-size: 24px;
            margin: 0 auto 2px;
          }
        }

        .card__twitter {
          margin: 10px 0 0;
        }
      }
    }
  }

  .footer {
    height: 785px;

    background: url("../assets/footer/bg-mobile.svg") no-repeat center center /
      cover;

    &__panel {
      width: 242px;
      height: 376px;
      border-radius: 56px 56px 48px 48px;
      border: 1.1px solid #101820;
      padding: 4px 2px;

      &-pipes {
        .pipe {
          width: 105px;

          &-1 {
            left: -105px;
            top: 16%;
          }

          &-2 {
            left: -105px;
            bottom: 16%;
          }

          &-3 {
            right: -105px;
            top: 16%;
          }

          &-4 {
            right: -105px;
            bottom: 16%;
          }
        }
      }

      &-text {
        span {
          width: 100%;
          font-size: 54px;
        }
      }

      &-button {
        bottom: -15px;
        transform: translateX(-50%) scale(1.02);
      }
    }

    &__info {
      // position: absolute;
      // bottom: 54px;
      // height: 170px;
      // padding-bottom: 25px;
      // background: url('../assets/footer/bg-info-mobile.svg') no-repeat center center / cover;
      // flex-flow: column nowrap;
      // justify-content: space-around;

      &-author {
        margin: 0 auto;
        order: 3;
      }

      &-contract {
        margin: 0 auto;
        order: 2;
        font-size: 15px;
      }
    }
  }

  .interlayer {
    &__stone {
      height: 74px;
      border-bottom: 1.15px solid #101820;

      &-img {
        height: 110%;
      }
    }

    &__blocks {
      &-img {
        &.blue {
          background: url("../assets/interlayers/blocks-green.svg") no-repeat
            center center / cover;
        }
      }
    }
  }

  .popup.project__info .popup__panel {
    max-width: 450px;
    .popup__panel-wrapper {
      padding: 0px 0px 30px;

      color: black!important;

      .popup__title {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }

  .plan {
    height: 2950px;
    background: url("../assets/plan/bg.jpg") no-repeat center center / cover;
    position: relative;

    .plan__upper-pipe-bg {
      display: none;

      &.mobile {
        display: block;
      }
    }

    &__title {
      @include shadowText(1.75px, #101820);
      margin-top: 60px;
      line-height: 48px;

      span {
        &:first-child {
          font-size: 48px;
        }

        &:last-child {
          margin-top: -30px;
          font-size: 54px;
        }
      }
    }

    &__map {
      height: auto;
      width: 100%;
      margin: 74px auto 0;

      .rivet {
        border: 1.1px solid #101820;

        &.upper {
          top: -30px;
        }

        &:after {
          border: 1.1px solid #101820;
        }
      }
    }

    &__pipe {
      padding: 5px 4px;
      height: 2600px;
      width: 42px;
      border: 1.1px solid #101820;
    }

    &__step {
      &-progress {
        width: 116px;
        height: 74px;
        border: $default-border-mobile;

        &-value {
          font-size: 34px;
        }

        &-note {
          font-size: 11px;
        }

        .inner {
          &:before {
            border: 8px solid rgba(255, 255, 255, 0.24);
          }
        }
      }

      .plan__card {
        position: relative;
        margin: 15px auto;
        width: 343px;
        height: 254px;
        //&-text {
        //  width: 20em;
        //}

        &-title {
          margin: 0 auto;
          width: 90%;
          font-size: 40px;
          @include shadowText(1.1px, #101820);
        }

        &-subtitles {
          width: 75%;
          margin: 0 auto;
          margin-top: 15px;
        }

        &-subtitle {
          margin: 0 auto 5px;
          letter-spacing: -1px;

          &:before {
            left: -4%;
          }
        }
      }

      &.step-1 {
        top: 170px;

        .bg-pipes {
          display: none;
        }

        .plan-bg__pipe {
          display: none;
        }

        .plan__card {
          width: 343px;
          height: 254px;
          background: url("../assets/plan/cards/card-1-mobile.svg") no-repeat
            center center / cover;

          &-decoration {
            .decor-1 {
              top: -3px;
              left: 50px;
              width: 75px;
              height: auto;
            }

            .decor-2 {
              top: -8px;
              left: -52px;
              width: 140px;
              transform: rotate(-40deg);
              height: auto;
            }

            .decor-3 {
              top: 10px;
              right: -5px;
              width: 80px;
              transform: scaleX(-1) rotate(-30deg);
              height: auto;
            }

            .decor-4 {
              bottom: -38px;
              left: 50%;
              transform: translateX(-50%);
              min-width: 613px;
              z-index: 2;
              height: auto;
              display: none;
            }

            .decor-5 {
              display: block;
              bottom: -50px;
              left: 50%;
              transform: translateX(-50%);
              min-width: 380px;
              z-index: 2;
              height: auto;
            }
          }
        }
      }

      &.step-2 {
        top: 640px;

        .plan__card {
          width: 375px;
          background: url("../assets/plan/cards/card-2-mobile.svg") no-repeat
            center center / cover;

          &-subtitle {
            letter-spacing: 0;
          }

          &-decoration {
            .decor-1 {
              right: -22px;
              width: 70px;
            }
          }
        }
      }

      &.step-3 {
        top: 1103px;

        .bg-pipes {
          display: none;
        }

        .plan__card {
          margin-top: 29px;
          background: url("../assets/plan/cards/card-3-mobile.svg") no-repeat
            center center / cover;
          box-shadow: 0 0 0 13px #101820, 0 0 143px #ffffff;
          border-radius: 14px;
          z-index: 5;

          &-decoration {
            .decor-1 {
              display: none;
            }
          }

          &-title {
            font-size: 40px;
            width: 70%;
            line-height: 52px;

            span {
              display: inline;
              background-color: #000;
              box-shadow: -15px 0 0 #000, 20px 0 0 #000;
            }
          }
        }
      }

      &.step-4 {
        top: 1580px;

        .plan__card {
          width: 355px;
          background: url("../assets/plan/cards/card-4-mobile.svg") no-repeat
            center center / cover;
          box-shadow: 0 0 0 14px #fff;

          &-decoration {
            .decor-1 {
              top: -90px;
              right: -82px;
              width: 190px;
              height: auto;
              transform: rotate(-15deg);
            }

            .decor-2,
            .decor-3,
            .decor-5,
            .decor-4 {
              display: none;
            }
          }
        }
      }

      &.final {
        top: 2120px;
        width: auto;
        height: 320px;

        .wrapper {
          width: 253px;

          .creep {
            position: absolute;
            left: -90px;
            top: 50px;
            z-index: 2;
          }
        }

        .vessel {
          border: 1.1px solid #101820;
          padding: 2px;
          z-index: 4;

          &.left,
          &.right {
            width: 260px;
            height: 308px;
            padding: 3px 2px;

            .inner {
              backdrop-filter: blur(2px);
              z-index: 10;
            }

            .coin {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 5;
            }
          }

          &.center {
            padding: 3px;

            .upper-rivet {
              position: absolute;
              width: 164px;
              height: 70px;
              transform: translateX(-50%);
              left: 50%;
              top: -67px;
            }

            .lower-rivet {
              position: absolute;
              width: 184px;
              height: 70px;
              transform: translateX(-50%);
              left: 50%;
              bottom: -71px;
            }

            .vessel__button {
              position: absolute;
              bottom: -21px;
              left: 50%;
              transform: translateX(-50%);
              width: 270px;
              height: 36px;
              border-radius: 9px;
              border: 1.75px solid #101820;
              background-color: #fa4b28;
              box-shadow: 0 0 40px #ff4040;
              display: flex;
              padding: 4px;

              .progress {
                margin: auto 0;
                height: 100%;
                width: 74%;
                background-color: rgba(242, 244, 248, 0.31);
                border-radius: 4px;
                display: flex;
              }

              span {
                margin: auto auto auto 8px;
                font-size: 16px;
                font-family: TerminusBold, sans-serif;
                color: #101820;
                white-space: nowrap;
                text-transform: uppercase;
                text-align: center;
              }
            }

            .inner {
              background-color: #20baa8;
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              position: relative;

              .vessel__title {
                width: 100%;
                text-shadow: 1.75px 0 1.75px #3bbfbe, 0 1.75px 1.75px #3bbfbe,
                  -1.75px 0 1.75px #3bbfbe, 0 1.75px 1.75px #3bbfbe,
                  0 1.44px 13px rgba(158, 238, 255, 0.72);
                text-align: center;
                text-transform: uppercase;
                font-size: 53px;
                letter-spacing: 5px;
                font-family: "Amatic SC", cursive;
                font-weight: 700;
              }

              .vessel__subtitle {
                margin-top: 15px;
                text-shadow: 0 1.19px 11px rgba(158, 238, 255, 0.72);
                font-size: 24px;
                text-align: center;
                font-family: Terminus, Arial, sans-serif;
                color: #101820;
              }
            }
          }

          &.left {
            position: relative;

            .inner {
              background-color: rgba(32, 130, 186, 0.82);

              .vessel__divisions {
                height: 70%;
                left: 12px;
              }
            }

            .left-river {
              width: 112px;
              height: 132px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -112px;
            }

            .lower-river {
              width: 164px;
              height: 125px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -119px;
            }
          }

          &.right {
            .right-river {
              width: 112px;
              height: 132px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -112px;
            }

            .lower-river {
              width: 164px;
              height: 125px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -119px;
            }

            .inner {
              background-color: rgba(174, 186, 32, 0.82);
            }
          }

          .inner {
            width: 100%;
            height: 100%;
            border-radius: 50px;
            position: relative;

            .vessel__divisions {
              position: absolute;
              height: 77%;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }

  .header-scroll {
    display: none;
  }

  .intro.active-menu {
    height: 100vh;
  }

  .header {
    margin: 0;

    &__button {
      display: none;
    }
  }

  .header__nav {
    display: none;
  }

  .header__main {
    &-globe {
      width: 75%;
      transform: translateX(-50%) scale(1.6, 1.6);
      margin-bottom: -100px;
    }

    &-creep {
      bottom: 20%;
      height: auto;
      width: 75%;
      transform: translate(-48%, -50%) scale(1.45, 1.5);
    }

    &-callout {
      display: none;

      &-mobile {
        display: block;
        position: absolute;
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        bottom: 33%;
        left: 50%;
        transform: translate(-50%);
        z-index: 25;
      }
    }

    &-progress {
      width: 90%;
      min-width: 50%;
      height: 48px;
      border: $default-border-mobile;

      .progress {
        height: 35px;

        &__inner {
          box-shadow: 0 0 40px rgba(255, 255, 255, 0.2);
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 6px solid rgba(255, 255, 255, 0.25);
          border-radius: 44px;
          z-index: 1;
        }
      }
    }
  }

  .header__menu-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #0b302b;
    z-index: 100;

    &.active {
      display: block;
    }

    .logo {
      display: block;
      height: 150px;
      width: 150px;
      margin: 80px auto 0;
    }

    .header__menu-navs {
      display: flex;
      flex-flow: column nowrap;
      width: 240px;
      margin: 70px auto 0;
      overflow: visible;
    }

    .header__menu-nav {
      cursor: pointer;

      &.disabled {
        &:hover {
          text-decoration: none;
          cursor: default;
        }

        span {
          color: #000;
        }
      }

      span {
        margin: auto;
      }
    }

    .links {
      display: flex;
      margin: 60px auto;
      flex-flow: row nowrap;
      justify-content: center;

      img {
        margin-right: 20px;
        filter: drop-shadow(0 0 40px rgba(115, 255, 66, 0.36));

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .pipe {
      background: url("../assets/intro/mobile-nav.svg");
      position: fixed;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 40px;
    }

    .header__menu-nav {
      width: 100%;
      height: 38px;
      text-align: center;
      background-color: #0f2320;
      border: 1.75px solid #101820;
      border-radius: 42px;
      margin-bottom: 24px;
      box-shadow: 0 0 40px rgba(115, 255, 66, 0.36);
      display: flex;

      span {
        margin: auto;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: #8dff1f;
        text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .header__logo {
    z-index: 15;
    max-width: 35%;
    margin-left: 15px;
    margin-top: 0px;
    transform: rotate(15deg);
  }

  .header__nav-mobile {
    display: block;
    position: absolute;
    width: 100%;
    height: 40px;
    background: url("../assets/intro/mobile-nav.svg") no-repeat center center /
      cover;
    left: 0;
    top: 5px;

    &-button {
      position: absolute;
      display: flex;
      top: -5px;
      right: 3px;
      width: 48px;
      height: 48px;
      border-radius: 10px;
      border: $default-border;
      background-color: #0b302b;

      &.close {
        background-color: #fa4b28;
        box-shadow: 0 0 80px rgba(255, 64, 64, 0.9);
        border: 1.75px solid #101820;

        .inner {
          background-color: rgba(242, 244, 248, 0.51);
          position: relative;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(135deg);
            width: 20px;
            height: 4px;
            background-color: #0f2320;
          }

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 4px;
            height: 20px;
            background-color: #0f2320;
          }

          div {
            display: none;
          }
        }
      }

      .inner {
        margin: auto;
        width: 36px;
        height: 34px;
        background-color: #425855;
        border-radius: 4px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 7px 6px;

        div {
          margin-bottom: 4px;
          height: 4px;
          width: 24px;
          background-color: #0f2320;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .city {
    background-color: $color-bg-city-mobile;

    &__bg {
      //display: none;

      .cloud-1 {
        left: -2%;
        top: 4%;
        width: 100px;
        animation: cloud1-mobile 20s infinite linear 0.4s;
      }

      .cloud-2 {
        right: 50%;
        top: 12%;
        width: 140px;
        animation: cloud2-mobile 20s infinite linear 0.7s;
      }

      .cloud-3 {
        left: -50%;
        top: 50%;
        width: 220px;
        animation: cloud3-mobile 20s infinite ease 1s;
      }

      .houses {
        &-left,
        &-right {
          width: 100%;
          height: 100%;
          position: absolute;

          img {
            width: 70%;
          }
        }

        &-left {
          &__lvl-1 {
            left: -53px;
            z-index: 20;
          }

          &__lvl-2 {
            bottom: 140px;
            left: -100px;
          }
        }

        &-right {
          right: -28px;

          &__lvl-1 {
            bottom: 0;
            right: -100px;
          }

          &__lvl-2 {
            bottom: 95px;
            right: -150px;
          }

          &__lvl-3 {
            display: none;
          }
        }
      }

      .further-bg {
        &__left {
          display: none;
        }

        &__right {
          right: 10%;
          bottom: 0;
        }
      }

      .creeps {
        img {
          width: 90%;
        }

        &__black {
          left: -30%;
          bottom: -7%;
          z-index: 100;
        }

        &__trump {
          right: -20%;
          bottom: -30px;
          z-index: 100;
        }
      }

      .grey-block {
        display: none;
      }

      .nlo {
        width: 140px;
        height: 100%;
        top: 50%;
        animation: nlo 15s infinite ease 0.2s;
        left: -2%;
        z-index: 14;

        &__model {
          width: 80%;
          height: 65px;
          z-index: 30;
        }

        &__vector {
          margin-top: -50px;
          z-index: 14;
          width: 100%;
          height: 300px;
        }
      }
    }
  }

  .statistic {
    padding: 70px 0;

    &__pipe {
      position: absolute;
      left: 50%;
      top: -25px;
      z-index: 1;
      min-width: 100%;
    }

    &__pipe-lower {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      height: 370px;
      width: 50%;
    }

    &__gallery {
      margin: 0;

      &-cards {
        min-width: 90px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: center;
        margin-right: 25px;
        overflow: hidden;
      }

      &-card {
        border-radius: 7%;
        width: 214px;
        min-width: 214px;
        height: 214px;
        margin-right: 20px;

        .card__photo {
          margin: auto;
          width: 180px;
          height: 180px;
        }

        &:before {
          bottom: 7px;
          right: 13px;
          height: 3px;
          width: 3px;
        }

        .gallery-border-effect {
          .upper-line {
            left: 12px;
            top: 3px;
            width: 175px;
            height: 9px;
            border-radius: 43px;

            .upper-inner-line {
              left: 37px;
              border-radius: 43px;
              top: 3px;
              width: 126px;
              height: 3px;
            }
          }

          .lower-line {
            left: 12px;
            bottom: 3px;
            width: 175px;
            height: 9px;
            border-radius: 43px;

            .lower-inner-line {
              left: 12px;
              border-radius: 43px;
              top: 3px;
              width: 126px;
              height: 4px;
            }
          }
        }

        &.gif {
          min-width: 90px;
          width: 90px;
          height: 90px;

          .card__photo {
            margin: auto;
            width: 74px;
            height: 74px;
            border-radius: 9px;
          }

          &:before {
            bottom: 4px;
            right: 7px;
            height: 1px;
            width: 1px;
          }

          &:after {
            bottom: 2px;
            left: 7px;
            height: 3px;
            width: 67px;
            background-color: rgba(25, 27, 30, 0.11);
            border-radius: 18px;
          }

          &:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &__info {
      margin-top: 38px;

      &-title {
        font-size: 53px;
        margin: 0 auto;
        width: 90%;
        letter-spacing: 3px;
        text-align: center;
        text-transform: uppercase;
        @include shadowText(1.75px, #101820);
      }

      &-panels {
        display: flex;
        width: 95%;
        height: auto;
        margin-top: 50px;
        flex-flow: column nowrap;
        overflow: visible;

        .statistic__panel {
          width: 100%;
          box-shadow: 0 0 40px rgba(255, 255, 255, 0.4);
          margin: 0 0 25px 0;
          border: $default-border-mobile;
          max-height: 170px;

          &:first-child {
            .statistic__panel-subtitle {
              width: 30%;
            }
          }

          &:last-child {
            margin: 0;
          }

          .wrapper {
            border-radius: 44px;
            border: 8px solid rgba(255, 255, 255, 0.24);
          }

          &-title {
            width: 50%;
            margin: 10px auto 0;
          }

          &-subtitle {
            font-size: 17px;
            margin: 10px auto;
            width: 55%;
          }
        }
      }

      &-subtitle {
        font-size: 34px;
        margin: 50px auto;
        text-transform: uppercase;
      }

      &-additional-panels {
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          z-index: 1;
          width: 130%;
          height: 1em;
          background: url("../assets/statistic/vector.png") no-repeat center
            center / cover;
        }
      }
    }

    &__additional-panel {
      margin: 0 0 8px 0;
      max-width: 100%;
      width: 100%;
      padding: 0;
      border: 1.75px solid #101820;
      height: 40px;
      background-color: #0f2320;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .answers {
    &__container {
      width: 93%;
      margin: 0 auto;
      height: auto;
    }

    &__title {
      margin-top: 60px;
      font-size: 53px;
      margin-left: 24px;
      @include shadowText(1.1px, #101820);
    }

    &__items {
      margin-top: 25px;
      display: flex;
      flex-flow: column nowrap;
    }

    &__item {
      width: 100%;
      margin-bottom: 29px;

      &.active {
        .answer {
          min-height: 160px;
          padding: 30px 24px 16px 16px;
        }
      }

      &-lumps {
        min-width: 15px;
      }

      .answer {
        font-size: 15px;
        font-family: Terminus, sans-serif;
        line-height: 18px;
        width: 100%;
        background-color: #0b302b;
        top: -10px;

        span {
          display: none;
          transition: display 0.1s linear;
        }

        &:before {
          left: -1px;
          height: 21px;
          width: calc(100% + 2px);
        }
      }

      .question {
        min-height: 104px;
        padding: 14px 0 14px 2px;
        border-radius: 14px;

        &__button {
          min-width: 40px;
          min-height: 40px;
          right: -10px;

          &-inner:after {
            width: 55%;
            height: 1.75px;
          }

          &-inner:before {
            width: 1.75px;
            height: 55%;
          }
        }

        &__text {
          margin-right: 16px;
          min-height: 72px;
          height: auto;
          max-height: 100%;
          display: flex;
          font-family: TerminusBold, sans-serif;
          font-size: 18px;
          vertical-align: center;
          border-radius: 7px;
          background-color: rgba(255, 255, 255, 0.11);

          span {
            margin: auto 40px auto 20px;
          }
        }
      }
    }
  }

  .discord {
    max-height: 1550px;

    &__titles {
      width: 95%;
      margin: 50px auto;
    }

    &__title {
      font-size: 54px;
      @include shadowText(1.1px, $color-text-black-1);

      span {
        font-size: 34px;
      }
    }

    &__subtitle {
      text-align: center;
      font-size: 24px;
      margin-top: 16px;
    }

    .discord__content {
      flex-flow: column nowrap;
      width: 95%;
      margin: 35px auto 0;

      .discord__pipe {
        width: 80%;
        height: auto;
        flex-flow: row nowrap;

        .circle {
          margin: 0 20px;
        }

        .pipe {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 75%;
          height: 43px;
          border: $default-border-mobile;
          padding: 5px 7px;

          .inner {
            &:before {
              border: 2px solid rgba(255, 255, 255, 0.24);
            }
          }
        }
      }

      .discord__panels {
        width: 96%;
        margin: 32px auto;
        flex-flow: column wrap;
        justify-content: initial;

        .discord__panel {
          width: 100%;
          height: 170px;
          background-color: #0f2320;
          padding: 3px 4px;
          border: $default-border-mobile;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          .inner {
            height: 100%;
            width: 100%;
            border-radius: 44px;

            span {
              line-height: 110%;
              font-family: Terminus, Arial, sans-serif;
            }
          }
        }
      }
    }
  }

  .creators {
    min-height: 2500px;
    background: url("../assets/creators/bg-mobile.jpeg") no-repeat center center /
      cover;

    &__video {
      margin-top: 60px;

      .wrapper {
        width: 95%;
      }

      &-title {
        width: 70%;
        font-size: 54px;
      }

      &-player {
        width: 100%;
        margin: 32px auto 0;
        max-height: 400px;
        border-radius: 22px;
        border: 0.79px solid #101820;
        padding: 8px;

        &:after {
          width: 90px;
          height: 90px;
        }

        .video__player-pipe {
          display: none;
        }

        img.video__background {
          border-radius: 22px;
          border: 0.79px solid #000;
        }
      }

      &-lamps {
        width: 15px;
        bottom: 2px;

        .lamp.blue {
          background-color: #00c2ff;
        }

        .lamp.red {
          background-color: #ff0000;
        }

        .lamp {
          border: 0.79px solid #101820;
          width: 6px;
          height: 6px;
        }
      }
    }

    &__team {
      margin-top: 0;
      width: 100%;
      padding-top: 50px;

      &-title {
        font-size: 54px;
      }

      &-cards {
        margin: 32px auto 0;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &-card {
        width: 296px;
        margin: 0 auto 35px;

        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          margin: 0 auto 35px;
        }

        &:last-child {
          margin: 0 auto 50px;
        }

        .card__image {
          width: 296px;
          height: 296px;
          padding: 24px;

          &-status {
            top: 30px;
          }
        }

        .card__info {
          max-width: 192px;
          max-height: 55px;
          margin: -35px auto 0;
          padding: 3px 0 10px 0;

          &-name {
            font-size: 24px;
            margin: 0 auto 2px;
          }
        }

        .card__twitter {
          margin: 10px 0 0;
        }
      }
    }
  }

  .footer {
    height: 280px;

    background: url("../assets/footer/bg-mobile.svg") no-repeat center center /
      cover;

    &__panel {
      width: 242px;
      height: 376px;
      border-radius: 56px 56px 48px 48px;
      border: 1.1px solid #101820;
      padding: 4px 2px;

      &-pipes {
        .pipe {
          width: 105px;

          &-1 {
            left: -105px;
            top: 16%;
          }

          &-2 {
            left: -105px;
            bottom: 16%;
          }

          &-3 {
            right: -105px;
            top: 16%;
          }

          &-4 {
            right: -105px;
            bottom: 16%;
          }
        }
      }

      &-text {
        span {
          width: 100%;
          font-size: 54px;
        }
      }

      &-button {
        bottom: -15px;
        transform: translateX(-50%) scale(1.02);
      }
    }

    .footer__copy {
      flex-direction: column-reverse !important;
      margin-top: 35px;
      div {
        width: 100% !important;
        margin: 10px 0px;
      }
    }

    &__info {
      // position: absolute;
      // bottom: 54px;
      // height: 170px;
      // padding-bottom: 25px;
      // background: url('../assets/footer/bg-info-mobile.svg') no-repeat center center / cover;
      // flex-flow: column nowrap;
      // justify-content: space-around;

      &-author {
        margin: 0 auto;
        order: 3;
      }

      &-contract {
        margin: 0 auto;
        order: 2;
        font-size: 15px;
      }
    }
  }

  .interlayer {
    &__stone {
      height: 74px;
      border-bottom: 1.15px solid #101820;

      &-img {
        height: 110%;
      }
    }

    &__blocks {
      &-img {
        &.blue {
          background: url("../assets/interlayers/blocks-green.svg") no-repeat
            center center / cover;
        }
      }
    }
  }

  .plan {
    height: 2950px;
    background: url("../assets/plan/bg.jpg") no-repeat center center / cover;
    position: relative;

    .plan__upper-pipe-bg {
      display: none;

      &.mobile {
        display: block;
      }
    }

    &__title {
      @include shadowText(1.75px, #101820);
      margin-top: 60px;
      line-height: 48px;

      span {
        &:first-child {
          font-size: 48px;
        }

        &:last-child {
          margin-top: -30px;
          font-size: 54px;
        }
      }
    }

    &__map {
      height: auto;
      width: 100%;
      margin: 74px auto 0;

      .rivet {
        border: 1.1px solid #101820;

        &.upper {
          top: -30px;
        }

        &:after {
          border: 1.1px solid #101820;
        }
      }
    }

    &__pipe {
      padding: 5px 4px;
      height: 2600px;
      width: 42px;
      border: 1.1px solid #101820;
    }

    &__step {
      &-progress {
        width: 116px;
        height: 74px;
        border: $default-border-mobile;

        &-value {
          font-size: 34px;
        }

        &-note {
          font-size: 11px;
        }

        .inner {
          &:before {
            border: 8px solid rgba(255, 255, 255, 0.24);
          }
        }
      }

      .plan__card {
        position: relative;
        margin: 15px auto;
        width: 343px;
        height: 254px;
        //&-text {
        //  width: 20em;
        //}

        &-frame {
          &-left {
            background: url("../assets/plan/cards/left-frame.jpg");
            background-position-x: -200%;
            background-size: 150%;
          }

          &-right {
            background: url("../assets/plan/cards/right-frame.jpg");
            background-size: 150%;
          }
        }

        &-title {
          margin: 0 auto;
          width: 90%;
          font-size: 40px;
          @include shadowText(1.1px, #101820);
        }

        &-subtitles {
          margin-top: 15px;
        }

        &-subtitle {
          margin: 0 auto 5px;
          letter-spacing: -1px;

          &:before {
            left: -4%;
          }
        }
      }

      &.step-1 {
        top: 170px;

        .bg-pipes {
          display: none;
        }

        .plan-bg__pipe {
          display: none;
        }

        .plan__card {
          width: 343px;
          height: 254px;
          background: url("../assets/plan/cards/card-1-mobile.svg") no-repeat
            center center / cover;

          &-decoration {
            .decor-1 {
              top: -3px;
              left: 50px;
              width: 75px;
              height: auto;
            }

            .decor-2 {
              top: -8px;
              left: -52px;
              width: 140px;
              transform: rotate(-40deg);
              height: auto;
            }

            .decor-3 {
              top: 10px;
              right: -5px;
              width: 80px;
              transform: scaleX(-1) rotate(-30deg);
              height: auto;
            }

            .decor-4 {
              bottom: -38px;
              left: 50%;
              transform: translateX(-50%);
              min-width: 613px;
              z-index: 2;
              height: auto;
              display: none;
            }

            .decor-5 {
              display: block;
              bottom: -50px;
              left: 50%;
              transform: translateX(-50%);
              min-width: 380px;
              z-index: 2;
              height: auto;
            }
          }
        }
      }

      &.step-2 {
        top: 640px;

        .plan__card {
          width: 375px;
          background: url("../assets/plan/cards/card-2-mobile.svg") no-repeat
            center center / cover;

          &-subtitle {
            letter-spacing: 0;
          }

          &-decoration {
            .decor-1 {
              right: -22px;
              width: 70px;
            }
          }
        }
      }

      &.step-3 {
        top: 1103px;

        .bg-pipes {
          display: none;
        }

        .plan__card {
          margin-top: 29px;
          background: url("../assets/plan/cards/card-3-mobile.svg") no-repeat
            center center / cover;
          box-shadow: 0 0 0 13px #101820, 0 0 143px #ffffff;
          border-radius: 14px;
          z-index: 5;

          &-decoration {
            .decor-1 {
              display: none;
            }
          }

          &-title {
            font-size: 40px;
            width: 70%;
            line-height: 52px;

            span {
              display: inline;
              background-color: #000;
              box-shadow: -15px 0 0 #000, 20px 0 0 #000;
            }
          }
        }
      }

      &.step-4 {
        top: 1580px;

        .plan__card {
          width: 355px;
          background: url("../assets/plan/cards/card-4-mobile.svg") no-repeat
            center center / cover;
          box-shadow: 0 0 0 14px #fff;

          &-decoration {
            .decor-1 {
              top: -90px;
              right: -82px;
              width: 190px;
              height: auto;
              transform: rotate(-15deg);
            }

            .decor-2,
            .decor-3,
            .decor-5,
            .decor-4,
            .left-fire,
            .right-fire {
              display: none;
            }
          }
        }
      }

      &.final {
        top: 2120px;
        width: auto;
        height: 320px;

        .wrapper {
          width: 253px;

          .creep {
            left: -100px;
            top: 15px;
            z-index: 2;
            transform: scale(1.2);
          }
        }

        .vessel {
          border: 1.1px solid #101820;
          padding: 2px;

          &.left,
          &.right {
            width: 260px;
            height: 308px;
            padding: 3px 2px;

            .inner {
              backdrop-filter: blur(2px);
              z-index: 10;
            }

            .coin {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 5;
            }
          }

          &.center {
            padding: 3px;

            .upper-rivet {
              position: absolute;
              width: 164px;
              height: 70px;
              transform: translateX(-50%);
              left: 50%;
              top: -67px;
            }

            .lower-rivet {
              position: absolute;
              width: 184px;
              height: 70px;
              transform: translateX(-50%);
              left: 50%;
              bottom: -71px;
            }

            .vessel__button {
              position: absolute;
              bottom: -21px;
              left: 50%;
              transform: translateX(-50%);
              width: 270px;
              height: 36px;
              border-radius: 9px;
              border: 1.75px solid #101820;
              background-color: #fa4b28;
              box-shadow: 0 0 40px #ff4040;
              display: flex;
              padding: 4px;

              .progress {
                margin: auto 0;
                height: 100%;
                width: 74%;
                background-color: rgba(242, 244, 248, 0.31);
                border-radius: 4px;
                display: flex;
              }

              span {
                margin: auto auto auto 8px;
                font-size: 16px;
                font-family: TerminusBold, sans-serif;
                color: #101820;
                white-space: nowrap;
                text-transform: uppercase;
                text-align: center;
              }
            }

            .inner {
              background-color: #20baa8;
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              position: relative;

              .vessel__title {
                width: 100%;
                text-shadow: 1.75px 0 1.75px #3bbfbe, 0 1.75px 1.75px #3bbfbe,
                  -1.75px 0 1.75px #3bbfbe, 0 1.75px 1.75px #3bbfbe,
                  0 1.44px 13px rgba(158, 238, 255, 0.72);
                text-align: center;
                text-transform: uppercase;
                font-size: 53px;
                letter-spacing: 5px;
                font-family: "Amatic SC", cursive;
                font-weight: 700;
              }

              .vessel__subtitle {
                margin-top: 15px;
                text-shadow: 0 1.19px 11px rgba(158, 238, 255, 0.72);
                font-size: 24px;
                text-align: center;
                font-family: Terminus, Arial, sans-serif;
                color: #101820;
              }
            }
          }

          &.left {
            position: relative;

            .inner {
              background-color: rgba(32, 130, 186, 0.82);

              .vessel__divisions {
                height: 70%;
                left: 12px;
              }
            }

            .left-river {
              width: 112px;
              height: 132px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -112px;
            }

            .lower-river {
              width: 164px;
              height: 125px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -119px;
            }
          }

          &.right {
            .right-river {
              width: 112px;
              height: 132px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -112px;
            }

            .lower-river {
              width: 164px;
              height: 125px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -119px;
            }

            .inner {
              background-color: rgba(174, 186, 32, 0.82);
            }
          }

          .inner {
            width: 100%;
            height: 100%;
            border-radius: 50px;
            position: relative;

            .vessel__divisions {
              position: absolute;
              height: 77%;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }

  .statistic__pipe {
    left: 64rem;
    transform: scale(5);
    top: -25px;
    height: 1250px;
    min-width: 50%;
    width: 50%;
  }

  .statistic__pipe-lower {
    bottom: -10rem;
    right: -27rem;
    transform: scale(3.5);
    height: 370px;
    width: 50%;
  }

  .plan .plan__upper-pipe-bg {
    top: 9px;
  }

  .creators__team .bg__pipe-2 {
    top: -50rem;
    left: -15rem;
  }

  .footer .bg__pipe-2 {
    display: none;
  }

  .footer .bg__pipe-1 {
    left: -32px;
  }

  .footer {
    background: #0b302b;
  }

  .statistic__pipe {
    top: 3rem;
  }

  .discord {
    max-height: 1750px;
  }

  .creators__video .wrapper {
    width: 86%;
  }

  .city__bg .creeps img {
    width: 70%;
  }

  .city__bg .creeps__black {
    left: -10%;
    bottom: -9.5%;
  }

  .city__bg .creeps__trump {
    right: -11%;
  }
}

// Media width 500

@media (max-width: 450px) {
  .popup.project__info .staked-creeps-wrapper .staked-creep-card .item__data {
    flex-direction: column;
  }

  .popup.project__info .staking_container {
    width: 90%;
  }

  .popup.project__info .tabs-menu-wrapper .staking-menu-item,
  .popup.project__info .creepz-layout-wrapper {
    overflow-y: auto;
    justify-content: start;

    &::-webkit-scrollbar {
      display: none;
    }

    .staking-menu-item {
      font-size: 18px;
    }
  }

  .popup__input {
    width: 270px;
  }
  table {
    thead {
      th {
        padding: 10px 10px;
        white-space: nowrap;
      }
    }
    tbody {
      td {
        max-width: 140px;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 5px 15px;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .popup.project__info .bottom__buttons {
    width: 90%;
    border-radius: 20px;
    margin: 10px auto;
  }

  .popup.project__info .popup__panel {
    width: 95%;
    .popup__panel-wrapper.bank {
      display: flex;
    }
    &.mint__panel {
      height: 80%;
    }
  }

  .popup .popup__panel {
    width: 95%;
    &.mint__panel {
      height: 80%;
    }
  }

  .popup .mint_buttons {
    flex-wrap: wrap;

    input.loomi {
      width: 100%;
      font-size: 14px;
      margin: 5px auto;
    }

    div.loomi.tample {
      letter-spacing: 0em;
      font-size: 12px;
      line-height: 8px;
      text-align: center;
      padding-left: 10px;
      width: 47%;

      &.stats {
        width: 100%;
      }
    }
  }

  .popup.project__info .tabs-menu-wrapper.leaderboard,
  .popup.project__info .creepz-layout-wrapper.leaderboard_table {
    margin: 10px 0;
  }

  // .popup.project__info .popup__panel {
  //   max-width: 320px;
  // }
  .mobile {
    display: flex;

    &.stake-button {
      margin: 20px auto;
    }
  }
  .desktop {
    display: none;
  }
  // .intro {
  //   height: 100vh;
  // }

  .city__info-title {
    font-size: 47px;
    width: 95%;
  }

  .city__info {
    top: 13%;

    &-title {
      margin: 50px auto 0px;
    }
    &-text {
      margin: 15px auto;
      width: 92%;
      font-size: 15px;
    }
  }

  .city__bg .creeps__black {
    left: -20%;
  }

  .city__bg .creeps img {
    width: 80%;
  }

  .city__bg .nlo {
    display: none;
  }

  .discord .discord__content .discord__pipe .pipe {
    width: 75%;
  }

  .city .houses {
    &-left {
      &__lvl-2,
      &__lvl-3 {
        display: none;
      }
    }

    &-right {
      &__lvl-2,
      &__lvl-3 {
        display: none;
      }
    }
  }

  .statistic__info-panels
    .statistic__panel:first-child
    .statistic__panel-subtitle {
    width: 80%;
  }

  .popup.project__info .popup__panel.bank_info {
    height: 80%;
  }

  .popup.project__info .bank_input_container {
    width: 100%;
  }

  // .header__main-globe {
  //   width: 90%;
  //   object-fit: contain;
  //   transform: translateX(-50%) scale(1.6, 1.6);
  // }

  .header__main {
    margin-top: 40px;
    padding-top: 0px;

    &-map {
      margin-top: -200px;
    }

    &-lizard {
      width: 320px;
      height: 600px;

      img {
        width: 320px;
      }

      .left-eye {
        width: 75px;
        left: 16%;
        top: 19%;
      }

      .right-eye {
        width: 75px;
        right: 16%;
        top: 19%;
      }

      .left-hand {
        width: 200px;
        left: -30%;
        top: 45%;
      }

      .right-hand {
        width: 200px;
        right: -30%;
        top: 45%;
      }
    }
  }

  // .header__main-creep {
  //   bottom: 30%;
  //   width: 85%;
  //   transform: translate(-48%, -50%) scale(1.45, 1.5);
  // }

  .statistic__pipe-lower {
    bottom: -10rem;
    right: -19.5rem;
    height: 370px;
  }

  .statistic__pipe {
    left: 45rem;
    transform: scale(5);
    top: -20rem;
    height: 2100px;
    min-width: 50%;
    width: 50%;
  }

  .city__bg .creeps__black {
    left: -10%;
    bottom: -450%;
  }

  .city__bg .creeps__black {
    left: -14%;
  }

  .city__bg .creeps__trump {
    right: -14%;
  }

  .rarity {
    background: url("../assets/rarity/rarity-m-bg.jpeg") no-repeat center /
      cover !important;
    padding-bottom: 250px;

    .lizard {
      display: none;
    }

    .rarity-background {
      background: none;
    }

    .rarity-inner {
      padding: 0 !important;
      text-align: center;

      .rarity-desktop {
        display: none;
      }

      .rarity-mobile {
        display: inline !important;

        img {
          margin: 30px 0 0;
        }
      }
    }
  }
}

// Media width 400

@media (max-width: 400px) {
  .discord .discord__content .discord__pipe {
    width: 95%;
  }

  .discord .discord__content .discord__pipe .pipe {
    width: 70%;
    height: 39px;
  }
}

// Media width 326

@media (max-width: 326px) {
  .statistic__info-panels
    .statistic__panel:first-child
    .statistic__panel-subtitle {
    width: 60%;
  }

  .popup.project__info .popup__panel {
    width: 320px;
  }

  .statistic__info-panels .statistic__panel {
    max-height: 190px;
  }

  .plan__step.step-1 .plan__card {
    width: 300px;
    height: 210px;
  }

  .plan__step .plan__card-subtitles {
    font-size: 15px;
  }

  .plan__step .plan__card-title {
    width: 80%;
    font-size: 35px;
  }

  .plan__step.step-1 .plan__card-decoration .decor-5 {
    bottom: -40px;
    min-width: 320px;
  }

  .plan__step.step-2 .plan__card {
    width: 320px;
    height: 215px;
  }

  .plan__step.step-3 .plan__card {
    width: 290px;
    height: 215px;
  }

  .plan__step.step-4 .plan__card {
    width: 280px;
    height: 205px;
  }

  .plan__step.step-4 .plan__card-decoration .decor-1 {
    width: 165px;
  }

  .plan__step.step-4 .plan__card-subtitles {
    width: 80%;
    margin: 15px auto;
  }

  .discord {
    max-height: 1750px;
  }

  .creators__video-title {
    width: 90%;
    font-size: 47px;
  }

  .creators__video .wrapper {
    margin: 55px auto 0;
  }

  .creators__team-card {
    width: 266px;
    margin: 0 auto 25px;
  }

  .creators__team-card .card__image {
    width: 246px;
    height: 246px;
    margin: 0 auto;
  }

  .answers__item.active .answer {
    min-height: 200px;
  }

  .footer__info-contract {
    margin: 0 auto;
    order: 2;
    font-size: 12px;
  }

  .footer__panel {
    margin: 80px auto;
  }

  .header__main-button {
    position: absolute;
    display: none;
    width: 175px;
    height: 40px;
    bottom: 12%;
    left: 50%;
    z-index: 15;
    transform: translateX(-50%);
  }

  .news {
    height: 50px;
    font-size: 26px;
  }

  .header__main-progress {
    width: 88%;
    min-width: 50%;
    height: 40px;
  }

  .header__main-progress .progress {
    height: 30px;
    font-size: 19px;
  }

  .header__nav-mobile {
    height: 35px;
    top: 4px;
  }

  .header__menu-mobile .logo {
    display: block;
    height: 120px;
    width: 120px;
    margin: 60px auto 0;
  }

  .header__menu-mobile .header__menu-navs {
    width: 240px;
    margin: 30px auto 0;
  }

  .header__menu-mobile .links {
    margin: 40px auto;
  }

  .header__menu-mobile .pipe {
    height: 35px;
  }

  .popup {
    &.step-1 {
      .popup__panel {
        width: 310px;
        height: 265px;
      }

      .popup__success {
        width: 85%;
        font-size: 26px;
      }
    }

    &__panel {
      width: 310px;
      height: 530px;
      padding: 5px 4px;

      &-wrapper {
        border: 6px solid rgba(255, 255, 255, 0.09);
      }
    }

    &__title {
      width: 80%;
      font-size: 25px;
      line-height: 120%;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: -0.06em;
      color: #8dff1f;
      margin: 15px auto 0;
      text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
    }

    &__card {
      margin: 10px auto 0;
      width: 85%;
    }

    &__input {
      width: 250px;
      margin: 20px auto 0;
    }

    &__choices {
      width: 70%;
      margin: 15px auto 0;
    }

    &__choice {
      height: 38px;
    }

    &__button {
      width: 235px;
      height: 54px;

      span {
        font-size: 18px;
      }
    }

    &__button-close {
      top: -10px;
      right: -5px;
      width: 60px;
      height: 60px;
      padding: 7px;
    }

    &__success {
      font-size: 34px;
      line-height: 120%;
      text-align: center;
      letter-spacing: -0.06em;
      color: #8dff1f;
    }
  }

  .city__info {
    margin: 0px auto 0;
    width: 100%;
  }

  .city__info-title {
    margin: 0px auto 0;
    font-size: 45px;
    width: 95%;
  }

  .city__info-text {
    margin: 20px auto 0;
    width: 95%;
    font-size: 15px;
  }

  .discord .discord__content .discord__panels .discord__panel .inner {
    border: 7px solid rgba(255, 255, 255, 0.09);
    padding: 15px 30px;
  }

  .discord__button {
    height: 50px;
    display: flex;
    width: 220px;
  }

  .city__info {
    margin: 150px auto 0;
  }

  .city__bg .creeps img {
    width: 90%;
  }

  .city__bg .creeps__black {
    left: -20%;
    bottom: -5%;
  }

  .city__bg .creeps__trump {
    right: -18%;
  }
}
