
// Colors
$color-blue-1: #0D063E;
$main-color-text: #fff;
$color-text-black-1: #101820;
$color-cyan: #20bba8;

$default-border: 1.75px solid #101820;
$default-border-mobile: 1.1px solid #101820;

$color-green-panel: #8DFF1F;

$color-bg-city: #091954;
$color-bg-city-mobile: #0F2347;

$speed-news: 10s;
$color-border-card: #101820;

$color-bg-panel: #499F95;
$color-border-panel: #101820;